import React, { useEffect, useState } from 'react';
import './index.scss';
import Button from '../../../components/ButtonPink';
import image from '../../../assets/img/3d-logo-dark-bg.jpg';
import about1 from '../../../assets/img/any kind 3d.jpg';
import about2 from '../../../assets/img/any kind 2.jpg';
import about3 from '../../../assets/img/any kin 3.jpg';
import about4 from '../../../assets/img/any kind 4.jpg';
import about5 from '../../../assets/img/any1.jpg';
import about6 from '../../../assets/img/any2.jpg';
import Footer from "../../Footer";






export default function AnykindServices() {


    return (<>
        <div className='container-fluid mx-0 mb-4 p-1 bg-white text-center'>

            <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
                <div className='h3 animate__animated animate__fadeInDown'>
                    <Button name="Anykind of Events" styles="h3" />
                </div>
            </div>

            <div className='container-fluid mx-0 my-2 p-0 d-flex flex-wrap justify-content-around manage-services go-flex'>


                <div className="card my-2 mx-2 rounded shadow card-30" >
                    <a href={about2}><img className="card-img-top" src={about2} alt="Card image cap" /></a>
                    <div className="card-body p-1 bg-secondary text-white text-left">
                        <ul>
                            <li>Weddings</li>
                           </ul>
                    </div>
                </div>

                <div className="card my-2 mx-2 rounded shadow card-30" >
                    <a href={about3}><img className="card-img-top" src={about3} alt="Card image cap" /></a>
                    <div className="card-body p-1 bg-secondary text-white text-left">
                        <ul>
                            <li>Baby Shower
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div className="card my-2 mx-2 rounded shadow card-30" >
                    <a href={about5}><img className="card-img-top" src={about5} alt="Card image cap" /></a>
                    <div className="card-body p-1 bg-secondary text-white text-left">
                        <ul>
                            <li>Catering 
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card my-2 mx-2 rounded shadow card-30" >
                    <a href={about6}><img className="card-img-top" src={about6} alt="Card image cap" /></a>
                    <div className="card-body p-1 bg-secondary text-white text-left">
                        <ul>
                            <li>Our Drinks 
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="card my-2 mx-2 rounded shadow card-30 " >
                    <a href={about1}><img className="card-img-top" src={about1} alt="Card image cap" /></a>
                    <div className="card-body p-1 bg-secondary text-white text-left">
                        <ul>
                            <li>Ceremony
                            </li>
                            <li>Unveling Services
                            </li>
                            <li>Lounge/Cocktails
                            </li>
                        </ul>
                    </div>
                </div>

                
                <div className="card my-2 mx-2 rounded shadow card-30" >
                    <a href={about4}><img className="card-img-top" src={about4} alt="Card image cap" /></a>
                    <div className="card-body p-1 bg-secondary text-white text-left">
                        <ul>
                            <li>Memorial Services
                            </li>
                          
                        </ul>
                    </div>
                </div>

            </div>

            <hr className="my-2 mx-auto w-90 bg-secondary border" />
            
            <div className='text-left px-4 py-2 mx-2 text-white bg-pink shadow mx-3'>
                Let Our experts take care of your every need and wish to plan  and co-ordinate your picture perfect day. From sound design and food decor we will provide all the support you need to have a breath taking.
                We designed to impress your guests making your function one thing about us we bring you comfort & solace when you need it the most
                </div>
        </div>


        <Footer />
    </>)

}
