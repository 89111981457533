import React,{useEffect,useState} from 'react';
import './index.scss';
import Button from '../../components/ButtonPink';
import ButtonSquare from '../../components/ButtonSquare';
import SubmitForm from '../../components/SubmitForm';
import FooterHelp from '../FooterHelp';
import MSGDialogue from '../../components/Dialogue';
import { APIurl,rootUrl } from '../../App';
import axios from 'axios'







export default function Video(){
const form = 'GPS Application Form-2.pdf';

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
      };




      
const [loginmsg, setLogingmsg] = useState("");
const [colors, setColors] = useState("");
const [diaglogueID, setdiaglogueID] = useState("msg4");
const [logging, setLogging] = useState(false);

const  process = (event)=>{
  event.preventDefault();
  setLogingmsg("Uploading...... ");
  setColors(" text-white bg-secondary");
  setLogging(true);
  try {
    document.getElementById(diaglogueID).style.display = "block"; 
  } catch (error) {
    console.log("element is not found");
  }
 
let name = document.getElementById("name").value;
let phone = document.getElementById("phone").value;

let photo = document.getElementById("photo").files.item(0);

var ok  =1;



if(ok==1){
  
    
  axios.post(APIurl,{ 
      cmd: "addapplicationform",
      name:name ,
      phone:phone ,
      photo:photo   
},{
headers: {
'Content-Type': 'multipart/form-data'
}}
)
      .then((response) => {
      
        console.log(response.data);
        document.getElementById("register_form").reset();
        var res = response.data;
        if(!res["success"]){
            setLogingmsg(res["data"]);
            setColors(" text-white bg-danger");
        }
        else{
          setLogingmsg(res["data"]);
            setColors(" text-white bg-success");
            setTimeout(()=>{
             
              // eslint-disable-next-line no-restricted-globals
            location.href = rootUrl + "/home" ;
           // location.ref = ;
          },2000);
          
          
            
        }
        setLogging(true);
        document.getElementById(diaglogueID).style.display = "block"; 
        // eslint-disable-next-line no-restricted-globals
        //location.href = 'http://localhost:81/softrans/API.php';
          // Handle data
      })
      .catch((error) => {
        console.log(error);
      })
}
else{
    alert("Please fix errors!")
}
}



    
    return(<>
      {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""} 
    <div className='container-fluid mx-0 mb-4 p-1 bg-white'>

        <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
            <div className='h3 animate__animated animate__fadeInDown'>
                <Button name="Registration" styles = "h3"/>
            </div>
        </div>
        <div className='container-fluid mx-0 my-2 px-2'>
            <h4>To Join our funeral cover:</h4>

            <div className='text-orange mb-3 animate__animated animate__fadeIn animate__slower'>
                Download pdf form by clicking button below, fill it, then
                upload completed form using online form sytem below. 
            </div>
            <ButtonSquare name="Download Form" style=" animate__animated animate__fadeInUp " onClick={() => openInNewTab("https://gpsfuneralhome.co.za/assets/forms/"+form)} />
        </div>
       

        <div className='container-fluid mx-0 p-0 mb-4 animate__animated  animate__zoomIn'>
        <form className='form-group register-form container border text-center py-3 px-2 mb-4' method='post' id='register_form'  onSubmit={process}>
            <h4>
                Form Submission
            </h4>
            <hr  className='bg-secondary mx-4 my-2'/>

            <div className='row m-0'>
            <div className='form-group col-sm-6'>
                <input className='form-control' type="text" id="name" placeholder='Enter your name' required/>
            </div>

            <div className='form-group col-sm-6'>
                <input className='form-control' type="text"  id="phone" minLength={10}placeholder='Enter your phone number' required/>
            </div>
            </div>
           
            <div className='form-group'>
               <span> <b>Upload completed form</b></span>
                <input className='form-control ' type="file" id="photo"  required/>
            </div>
            <SubmitForm name="Submit form"/> 
        </form>
        </div>
    </div>

    <div className=' animate__animated animate__fadeInUp  animate__slower container-fluid m-0 p-0 position-fixed bottom-0 left-0'>
        <FooterHelp />

    </div>


    </>)

}
    