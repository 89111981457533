import React,{useEffect,useState} from 'react';
import './index.scss';
import Button from '../../components/ButtonPink';
import ButtonSquare from '../../components/ButtonSquare';
import { APIurl,rootUrl,BaseAPIurl } from '../../App';
import axios from 'axios';
import MSGDialogue from '../../components/Dialogue';
import ImageGallery from 'react-image-gallery';


function Cards(props){

    return (
        <>
      <div className="card my-2 card-18" >
                <div className="card-header bg-pink text-white text-center">
                  <b>{props.title}</b>
                </div>
                <div className="card-body">
                   {props.menu.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink'></i> {item} <br/></span>)}
                </div>
        </div>
        </>
    )
}



export default function Drinks(props){

    const [photos,setPhotos] = useState([]);
    const [limit,setLimit] = useState(4);
    const [isEmpty,setEmpty] = useState("Loading Images");
    const [loginmsg, setLogingmsg] = useState("");
    const [colors, setColors] = useState("");
    const [logging, setLogging] = useState(false);
    const [diaglogueID, setdiaglogueID] = useState("msg16");

    useEffect(()=>{

    //Get all photos
    setLogingmsg("Getting Photos " + props.name +  " ...... ");
    setColors(" text-white bg-secondary");
    try {
      document.getElementById(diaglogueID).style.display = "block";
    } catch (error) {
      console.log("element is not found");
    }
    setPhotos([]);
    axios.post(APIurl,{
        cmd: "getphotobyid",
        id:props.name

        } )
        .then((response) => {

        var res = response.data;



        if(!res["success"]){
          console.log("check this ",res);
          setLogingmsg(res["data"]);
          setColors("Could not send request");
          setEmpty("No photo found");
        }
        else{

            if(res["data"].length ==0){

                setEmpty("No photo found");
              }
              else{
                setEmpty("");
              }


          const images = [];

          res["data"].forEach(element => {

            let el = { original: BaseAPIurl + "/Gallery/" + element.img,
                       thumbnail: BaseAPIurl + "/Gallery/" + element.img};
            images.push(el);

          });
          setPhotos(images);
          document.getElementById(diaglogueID).style.display = "none";
        }

        })
        .catch((error) => {

        console.log(error);

        });
    },[props]);

    const drinks = [

        {
            title: "Drinks",
            menu: ['Water bottles','Gemere','Soft drinks','Alcohol']
        },
          {
            title: "Deserts",
            menu:['Pudding','Ultramel','Caramel','Creamer']
        },
        {
            title: "Cocktail Packages",
            menu:['Professional bartenders & mixologists','Bar runner','Portable wooden Bar counter','Glassware hire','Alcoholic cocktails','Non-alcoholic cocktails']
        },
        {
            title: "Packages Plans",
            menu:['Bronze 30 pax','Silver 50 pax','Gold 100 pax', 'Diamond 200 pax', 'Platinum 300 pax']
        },
         {
            title: "Alcoholic Packages",
            menu:['Gin/Beer & Keg of your choice','Cider keg', '500ml plastic beer tumblers', 'CO2 Gas Canister hire','Non-alcoholic cocktails','glassware hire','Portable wooden Bar counter']
        },

    ];

    return(<>

{logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}

    <div className='container-fluid m-0 p-1 bg-white'>

        <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
            <div className='h3'>
                <Button name= {props.name + " Gallery"} styles = "h3"/>
            </div>
        </div>



    <div className='container-fluid mx-0 my-3 p-0 go-flex'>
           {
               drinks.map((item, index)=><Cards key={index} title={item.title} menu ={item.menu}/>)
           }
       </div>

        <div className='container-fluid mx-0 my-3 p-0 text-center'>
            <h4>Photos</h4>
            <h3 className='text-danger'>{isEmpty}</h3>
        </div>





        <ImageGallery autoPlay= {true} items={photos} />

        <ButtonSquare name="Join Our Funeral Services" Icon="plus" />
    </div>

    </>)

}
