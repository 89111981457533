import React,{useEffect,useState} from 'react';
import './index.scss';
import Button from '../../components/ButtonPink';
import ButtonSquare from '../../components/ButtonSquare';
import MSGDialogue from '../../components/Dialogue';
import { APIurl,rootUrl,BaseAPIurl } from '../../App';
import axios from 'axios'


function Photo(props){
    const [caption1, setCaption] = useState("");
    const [shorten, setShorten] = useState(true);
   
const ReadMore = ()=>{
    setShorten(false);
  setCaption(props.caption);
}

useEffect(()=>{
    var text = "";
    var i =0;
    while(text.length<50){
           
            text = text +  props.caption[i];
        i++;
        if(i>props.caption.length-1){
            setShorten(false);
            break;
           
        }
    }
    text = text + "...";
    setCaption(text);
    if(!shorten){
        setCaption(props.caption);
    }
},[]);
    return (
        <>
        <div className="card my-2 mx-2 rounded animate__animated  animate__zoomIn" >
            <img className="card-img-top" src={BaseAPIurl + "/Gallery/" +  props.img} alt="Card image cap"/>
            <div className="card-body p-1 bg-secondary text-white text-left">
               <p className='animate__animated animate__fadeIn animate__slower'>
                    {caption1}{shorten? <small onClick={ReadMore} className=' p-1 mx-1 my-0 small bg-secondary text-warning bg-white border rounded'>Read more+</small> : ""}
               </p>
                <Button name="Delete" parentStyles=" align-items-end" href={"/photodelete?id=" + props.id}/>
            </div>
        </div>
        </>
    )
}

function Videos(props){
    const [caption1, setCaption] = useState("");
    const [shorten, setShorten] = useState(true);
   
const ReadMore = ()=>{
    setShorten(false);
  setCaption(props.caption);
}

useEffect(()=>{
    var text = "";
    var i =0;
    while(text.length<50){
           
            text = text +  props.caption[i];
        i++;
        if(i>props.caption.length-1){
            setShorten(false);
            break;
           
        }
    }
    text = text + "...";
    setCaption(text);
    if(!shorten){
        setCaption(props.caption);
    }
   
},[]);
    return (
        <>
        <div className="card my-2 mx-2 rounded" >
        <video className='card-img-top' width="320" height="240" controls>
        <source src={BaseAPIurl + "/Videos/" + props.video} type="video/mp4"/>
       
        Your browser does not support the video tag.
        </video>
           
            

            <div className="card-body p-1 bg-secondary text-white text-left">
            <h5 >{props.title}</h5>
               <p>
                    {caption1}{shorten? <small onClick={ReadMore} className=' p-1 mx-1 my-0 small bg-secondary text-warning bg-white border rounded'>Read more+</small> : ""}
               </p>
           
                <Button name="Delete" parentStyles=" align-items-end" href={"/videodelete?id=" + props.id} />
            </div>
        </div>
        </>
    )
}


export default function Video(){

    const [photos,setPhotos] = useState([]);
    const [videos,setVideos] = useState([]);
    const [limit,setLimit] = useState(4);
    const [limitV,setLimitV] = useState(4);
    const [loginmsg, setLogingmsg] = useState("");
    const [colors, setColors] = useState("");
    const [logging, setLogging] = useState(false);
    const [diaglogueID, setdiaglogueID] = useState("msg16");
    
    
useEffect(() => {
   
  
    
    //Get all photos
      axios.post(APIurl,{ 
          cmd: "getallphotos"
          
          } )
          .then((response) => {
          var res = response.data;
          if(!res["success"]){   
            console.log("check this ",res);
              setLogingmsg(res["data"]);
              setColors("Could not send request");
          }
          else{
            if(res["data"].length>limit){
                res["data"] = res["data"].reverse();
                var newArr = [];
                for (let i=0;i<limit;i++){
                newArr.push(res["data"][i]);
                }
               
                setPhotos(newArr);
            }
            else{
                
                
                res["data"] = res["data"].reverse();
                setPhotos(res["data"] );
            }
            setPhotos(res["data"] );
           
          }
         
          })
          .catch((error) => {
    
          console.log(error.response.data);
          
          });

        
           
    //Get all Videos
      axios.post(APIurl,{ 
        cmd: "getallvideos"
        
        } )
        .then((response) => {
        var res = response.data;
        if(!res["success"]){   
            setLogingmsg(res["data"]);
            setColors("Could not send request");
        }
        else{
           
            if(res["data"].length>limitV){
               
                res["data"] = res["data"].reverse();
                var newArr = [];
                for (let i=0;i<limitV;i++){
                newArr.push(res["data"][i]);
                }
                newArr.reverse();
                setVideos(newArr);
            }
            else{
                res["data"].reverse();
                setVideos(res["data"] );
              
            }
            setVideos(res["data"] );
           
        }
       
        })
        .catch((error) => {
  
        console.log(error);
        
        });
    
    

    }, []);

    
    return(<>
     {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   
    <div className='container-fluid m-0 p-1 bg-white'>

        <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
            <div className='h3'>
                <Button name="Media Management" style=" animate__animated animate__fadeInDown "  styles = "h4"/>
            </div>
        </div>
        <div className='container-fluid mx-0 my-3 p-0'>
            <div className='container d-flex flex-row justify-content-between'>
                <ButtonSquare name="Add Video" href="/addvideo" style=" animate__animated animate__fadeInUp " Icon="video"/>
                <ButtonSquare name="Add Photo" href="/addphoto" style=" animate__animated animate__fadeInUp " Icon="camera"/>
            </div>
      
        </div>
        <div className='container-fluid mx-0 my-3 p-0 text-center'>
            <h4 className='animate__animated animate__fadeIn animate__slower'>Photos</h4>
            <div className='container-fluid mx-0 my-2 p-0 d-flex flex-wrap justify-content-around manage-photos'>
            {photos.map((slide) => <Photo key={slide.ID} id={slide.ID} caption={slide.caption} img={slide.img}/>)}

            </div>
            <ButtonSquare onClick={()=>{
                setLimit(100);
                
            }} name="More Photos " style=" animate__animated animate__fadeInUp " Icon="plus" />
        </div>
        <div className='container-fluid mx-0 my-3 p-0 text-center' id="videos">
            <h4 className='animate__animated animate__fadeIn animate__slower'>Videos</h4>
            <div className='container-fluid mx-0 my-2 p-0 d-flex flex-wrap justify-content-around manage-photos'>
            {videos.map((slide) => <Videos key={slide.ID} id={slide.ID} title={slide.title} caption={slide.caption} video={slide.img}/>)}

            </div>
            <ButtonSquare name="More Videos" style=" animate__animated animate__fadeInUp "  onClick={()=>{
                setLimitV(100);
            }} Icon="plus"/>
        </div>

    </div>

    </>)

}
    