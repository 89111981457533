import React, { useEffect } from "react";
import "./index.scss";
import ButtonSquare from "../../components/ButtonSquare";
import { Link } from "react-router-dom";
import safrican from "../../assets/img/logo-full-light.png";

export default function Slider(props) {
  useEffect(() => {}, []);
  return (
    <>
      <div
        className={"sliders container-fluid m-0 p-0 w-100 " + props.showslide}
      >
        <div className="container-fluid m-0 p-0 w-100 bg-slider position-absolute top-0"></div>
        <img className=" " src={props.bg} alt="background-home-pic" />
        <div className="bg-slider1 position-absolute p-hide"></div>
        <div className="slogan-safrican position-absolute m-0 p-0 p-hide">
          <h4 className="text-white p-1">
            {" "}
            Underwritten by RMA
            <br />
          </h4>
        </div>
        event
        <div className="title text-white">
          {" "}
          <h2 className="animate two">
            <span>G</span>
            <span>P</span>
            <span>S</span>&nbsp;
            <span>F</span>
            <span>U</span>
            <span>N</span>
            <span>E</span>
            <span>R</span>
            <span>A</span>
            <span>L</span>&nbsp;
            <span>H</span>
            <span>O</span>
            <span>M</span>
            <span>E</span>&nbsp;
            {/* <span>A</span>
                   <span>N</span>
                   <span>D</span>&nbsp;
                   <span>E</span>
                   <span>V</span>
                   <span>E</span>
                   <span>N</span>
                   <span>T</span>
                   <span>S</span> */}
          </h2>
        </div>
        <div className="home-slide-button">
          <ButtonSquare
            Icon="arrow-right"
            style=" my-4 animate__animated  animate__zoomIn"
            name={props.btn}
            href="register"
          />
          <div className="container-fluid mx-0 d-flex flex-row justify-content-center">
            <Link
              to="/fashion"
              className="btn btn-white border rounded text-dark shadow bg-white my-4 animate__animated animate__fadeInUp animate__slower"
            >
              <b>Learn more+</b>
            </Link>
          </div>
        </div>
        <div className="slide-control d-flex flex-row animate__animated animate__fadeInUp">
          {props.controls.map((style) => (
            <div key={style.id} className={"circle m-1 " + style.name}></div>
          ))}
          {/*<Controls controls={props.changeSlide} count={props.id} />*/}
        </div>
      </div>
    </>
  );
}
