import React,{useEffect,useState} from 'react';
import './index.scss';
import Button from '../../components/ButtonPink';
import ButtonSquare from '../../components/ButtonSquare';
import image from '../../assets/img/3d-logo-dark-bg.jpg';
import video from '../../assets/videos/last page progress.mp4'
import MSGDialogue from '../../components/Dialogue';
import { APIurl,rootUrl,BaseAPIurl } from '../../App';
import axios from 'axios';



function Videos(props){
    const [caption1, setCaption] = useState("");
    const [shorten, setShorten] = useState(true);
    var text = "";
    var i =0;
    while(text.length<50){

        if(i>=props.caption.length){
            break;
        }
        else{
            text = text +  props.caption[i];
            i++;
        }
       
    }
  
const ReadMore = ()=>{
    setShorten(false);
  setCaption(props.caption);
}

useEffect(()=>{
   
   
    if(props.caption.length<50){

        setShorten(false);

    }
    else{
        text = text + "...";
    }
    setCaption(text);
    
},[]);

    return (
        <>
        <div className="card my-2 mx-2 rounded animate__animated  animate__zoomIn" >
        <video className='card-img-top' width="320" height="240" controls>
        <source src={BaseAPIurl + "Videos/" + props.video} type="video/mp4"/>
                 Your browser does not support the video tag.
        </video>
            <div className="card-body p-1 bg-pink text-white text-left">
            <h5 className='animate__animated animate__fadeInUp'>{props.title}</h5>
               <p className='animate__animated animate__fadeIn animate__slower'>
                    {caption1}{shorten? <div onClick={ReadMore} className=' p-1 mx-1 my-0 small bg-secondary text-warning bg-white border rounded'>Read more+</div> : ""}
               </p>
            </div>
        </div>
        </>
    )
}


export default function Video(){

    const [videos,setVideos] = useState([]);
    const [loginmsg, setLogingmsg] = useState("");
    const [colors, setColors] = useState("");
    const [logging, setLogging] = useState(false);
    const [diaglogueID, setdiaglogueID] = useState("msg16");

            useEffect(()=>{

    //Get all Videos
    axios.post(APIurl,{ 
        cmd: "getallvideos"
        } )
        .then((response) => {
        var res = response.data;
        if(!res["success"]){  
          
            setLogingmsg(res["data"]);
            setColors("Could not send request");
        }
        else{
            console.log("Here",res["data"]); 
            setVideos(res["data"] );
           
        }
       
        })
        .catch((error) => {
  
        console.log(error);
        
        });
            },[])
    
    return(<>
    <div className='container-fluid m-0 p-1 bg-white'>

        <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
            <div className='h3 animate__animated animate__fadeInDown'>
                <Button name="Videos" styles = "h4"/>
            </div>
        </div>
       
       
        <div className='container-fluid mx-0 my-3 p-0 text-center'>
            <h4 className='animate__animated animate__fadeIn animate__slower'> Videos</h4>
            <div className='container-fluid mx-0 my-2 p-0 d-flex flex-wrap justify-content-around manage-photos'>
            {videos.map((slide) => <Videos key={slide.ID} id={slide.ID} title={slide.title} caption={slide.caption} video={slide.img}/>)}

            </div>
            <ButtonSquare name="More Videos" Icon="plus"/>
        </div>

    </div>

    </>)

}
    