import './index.scss'
import { useParams, Link } from 'react-router-dom'


export default function ImagePage() {
    const { src } = useParams()
    const imagesUrl = 'https://gpsfuneralhome.co.za/fashion/';
    return (
        <div className='container-fluid p-0 m-0'>
            <div className='container'>
                <img src={imagesUrl + src} className='img-fluid m-0 px-0 py-4' alt='...' />
            </div>
            <div className='container-fluid d-flex justify-content-center'>
                <Link to='/fashion' className='btn btn-outline-dark btn-lg m-3'>Back</Link>
            </div>
        </div>
    )
}