import React, { useEffect, useState } from 'react';
import './index.scss';
import Button from '../../../components/ButtonPink';
import image from '../../../assets/img/3d-logo-dark-bg.jpg';
import about1 from '../../../assets/img/policy 1.jpg';
import about2 from '../../../assets/img/policy 2.jpg';
import about3 from '../../../assets/img/policy 3d.jpg';
import about4 from '../../../assets/img/policy 4.jpg';
import Footer from "../../Footer";



function Cards(props){

    const count = props.menu.length/2;
const items = props.menu;
const firstHalf = items.slice(0, count);
const secondHalf = items.slice(count, count * 2);

    return (
        <>
      <div className="card my-2 mx-4 " >
                <div className="card-header bg-pink text-white text-center">
                  <b>{props.title}</b>
                </div>
                <div className=" card-body text-left go-flex justify-content-between d-pad-4">
                    <div className={'text-start container-fluid'}> {firstHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                  <div className={'text-start container-fluid'}>{secondHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>

                </div>
        </div>
        </>
    )
}

  const Faq = () => {
    const [selectedQuestion, setSelectedQuestion] = useState(null);
  
    const questions = [

                        {id:1,q:"How long does the claim take?",a:"Within 2 days after submitting all correct documentation"},
                        {id:2,q:"If I don't want to conduct the Funeral Services or Plan with GPS, can I still pay for catering only?",a:"yes, we can offer you catering services as a stand alone services"},
                        {id:3,q:"For cows what do you do?",a:"On the side of cow we buy for you if not give you a cash pay-out."},
                        {id:4,q:"What does Catering include?",a:"Our catering benefit includes caters who prepare and serve food using their own cooking equipment. We also offer waiters, chefs only if you want the full services of providing caterers, cooking equipment and Grocery."},
                        {id:5,q:"How many people do you cater for?",a:"Our catering team for everyone attending the funeral services no matter that size."},
                        {id:6,q:"Can you buy Groceries for me?",a:"Yes at an extra fee arrangement can be make for GPS to go and do grocery shopping for your funeral."},
                        {id:7,q:"Do you provie decorations in the tents?",a:"Yes, arrangement can be made to have tents at home decorated at an additional cost."},
                        {id:8,q:"What happens if I don't want your services GPS Funeral. Condition the Funeral Services for me?",a:"On our side GPS Funeral Home and Events, then your total cover benefit will be paid to the claimant in cash."},
                        {id:9,q:"Can you conduct or provide Funeral services in provinces",a:"No we don't. But if someone likes our services we can cover them at extra cost."},
                        {id:10,q:"Can I get a better coffin upgrade?",a:"Yes, you have an option of upgrading to a coffin caskets tiers, Domes of your choice at an extra cost."},
                        {id:11,q:"Do I get any cash pay-out to buy some certain things?",a:"yes you can."},
                        {id:12,q:"How many cars will I get or what kind of cars",a:"Each basic funeral services includes a hearse and Family cars. If you want high Standard should you require extra cars, they can be addd at an extra cost."},
                        {id:13,q:"How much will I get if I want a cash instead of your services?",a:"Will depend of your value of packages full benefit of your cover will be paid."},
                        {id:14,q:"Do you have a premium cash back after a 5 years?",a:"No cash back"},
                        {id:15,q:"What happen if my child turn 21 years?",a:"We don't remove the child"}

    ];
  
    return (
      <div className={'go-flex flex-wrap'}>
        {questions.map(question => (
          <div key={question.id} className="w-90 mx-auto rounded my-2 p-0 card card-45">
            <div  className='text-left px-2 py-1 d-flex flex-row justify-content-between'
              onClick={() => setSelectedQuestion(selectedQuestion === question.id ? null : question.id)}
            >
              <span>{question.q}</span> <i className='fa fa-angle-down text-danger h4'></i>
            </div>
            {selectedQuestion === question.id && <div className='m-0 p-1 bg-pink text-white text-left'>{question.a}</div>}
          </div>
        ))}
      </div>
    );
  };
  




export default function PolicyConditions() {


    const drinks = [

        {
            title: "Who qualifies as an extended?",
            menu: ['Daughter in-law','Secondary Spouse',
            'Fiance','Step Fater','Sister-in-law','uncle','Cousin',
            'Nience','Grand Father','Mother in-law', 'Son-in-law','step child',
            'Grand child','Step Mother','Brother-in-law','Spouse','Nephew','Grand Mother',
            'Sister','Father in law']
        }

    ];

    const Questions = [ {q:"How long does the claim take?",a:"Within 2 days after submitting all correct documentation"},
                        {q:"If I don't want to conduct the Funeral Services or Plan with GPS, can I still pay for catering only",a:"yes, we can offer you catering services as a stand alone services"},
                        {q:"For cows what do you do?",a:"On the side of cow we buy for you if not give you a cash pay-out."},
                        {q:"What does Catering include?",a:"Our catering benefit includes caters who prepare and serve food using their own cooking equipment. We also offer waiters, chefs only if you want the full services of providing caterers, cooking equipment and Grocery."},
                        {q:"How many people do you cater for?",a:"Our catering team for everyone attending the funeral services no matter that size."},
                        {q:"Can you buy Groceries for me?",a:"Yes at an extra fee arrangement can be make for GPS to go and do grocery shopping for your funeral."},
                        {q:"Do you provie decorations in the tents?",a:"Yes, arrangement can be made to have tents at home decorated at an additional cost."},
                        {q:"What happens if I don't want your services GPS Funeral. Condition the Funeral Services for me?",a:"On our side GPS Funeral home, then your total cover benefit will be paid to the claimant in cash."},
                        {q:"Can you conduct or provide Funeral services in provinces",a:"No we don't. But if someone likes our service you at extra cost."},
                        {q:"Can I get a better coffin upgrade?",a:"Yes, you have an option of upgrading to a coffin caskets tiers, Domes of your choice at an extra cost."},
                        {q:"Do I get any cash pay-out to buy some certain things?",a:"yes you can."},
                        {q:"How many cars will I get or what kind of cars",a:"Each basic funeral services includes a hearse and Family cars. If you want high Standard should you require extra cars, they can be addd at an extra cost."},
                        {q:"How much will I get if I want a cash instead of your services",a:"Will depend of your value of packages full benefit of your cover will be paid."},
                        {q:"Do you have a premium cash back after a 5 years?",a:"No cash back"},
                        {q:"What happen if my child turn 21 years?",a:"We don't remove the child"}];


    return (<>
        <div className='container-fluid mx-0 mb-4 p-1 bg-white text-center'>
            <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
                <div className='h3 animate__animated animate__fadeInDown'>
                    <Button name="Policy Conditions" styles="h3" />
                </div>
            </div>
            <div className='container-fluid m-0 px-1 my-3'>
                <h3>Frequenctly Asked Questions</h3>
                <div className='container-fluid mx-0 my-2 p-0 here manage-services'>
                    <Faq />
                </div>
            </div>

            
    <div className='container-fluid mx-0 my-3 p-0 '>
           {
               drinks.map((item, index)=><Cards key={index} title={item.title} menu ={item.menu}/>)
           }

       </div>

       <div className='container-fluid mx-0 my-2 p-0 d-flex flex-wrap justify-content-around manage-services'>


                <div className="card my-2 mx-2 rounded shadow card-45" >
                    <a href={about1}><img className="card-img-top " src={about1} alt="Card image cap" /></a>
                    <div className="card-body p-1 bg-secondary text-white text-left">
                        <ul>
                            <li>
                                Upgrade your package at no waiting period
                            </li>
                            <li>Adjust the number of member insured in the policy to match your affordability and more</li>
                        </ul>
                    </div>
                </div>

                <div className="card my-2 mx-2 rounded shadow card-45" >
                    <a href={about3}><img className="card-img-top " src={about3} alt="Card image cap" /></a>
                    <div className="card-body p-1 bg-secondary text-white text-left">
                        <ul>
                            <li>If we do not bury for you. We will give you a certain package according to the value of the package plan
                            </li>
                            <li>Even when a child turns 22 years of age, he/she will not be removed from the policy
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <hr className="my-2 mx-auto w-90 bg-secondary border" />
            <img src={about2} alt="Card image cap"  className="w-90 mx-auto rounded my-2 height-vp-70 cover" />
            <p>
                <b>Feature-Reassess your policy Regularly</b> <br/>
                <b className="text-pink">Check List:</b>
            </p>
            <ol className='text-left px-4 py-2 mx-2 text-dark'>
                <li>Request for your policy document to confirm your personal details and please
                    notify on any errors  
                </li>
                <li>Update your contact details it is not the businesses reponsibility to confirm the 
                    clients personal information
                </li>
                </ol>

        </div>


        <Footer />
    </>)

}
