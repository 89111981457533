import React,{useEffect,useState} from 'react';
import './index.scss';
import Button from '../../components/ButtonPink';
import ButtonSquare from '../../components/ButtonSquare';
import { APIurl,rootUrl,BaseAPIurl } from '../../App';
import axios from 'axios';
import MSGDialogue from '../../components/Dialogue';
import ImageGallery from 'react-image-gallery';


function Cards(props){

    const count = Math.ceil(props.menu.length / 3);

    const items = props.menu;
    const firstThird = items.slice(0, count);
    const secondThird = items.slice(count, count * 2);
    const thirdThird = items.slice(count * 2, count * 3);


    return (
        <>
      <div className="card my-2" >
                <div className="card-header bg-pink text-white text-center">
                  <b>{props.title}</b>
                </div>
                <div className="card-body go-flex">
                    <div>
                         {
                       firstThird.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink'></i> {item} <br/></span>)
                   }
                    </div>
                  <div>
                       {
                       secondThird.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink'></i> {item} <br/></span>)
                   }
                  </div>

                    <div>
                         {
                       thirdThird.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink'></i> {item} <br/></span>)
                   }
                    </div>


                </div>
        </div>
        </>
    )
}



function Card_2(props){
  const count = props.menu.length/2;
const items = props.menu;
const firstHalf = items.slice(0, count);
const secondHalf = items.slice(count, count * 2);

  return (
      <>
    <div className="card  my-3 p-0 container-fluid " >
              <div className="card-header bg-pink text-white text-center">
                <b>{props.title}</b>
              </div>
              <div className=" card-body text-left go-flex">
                  <div className={'text-start container-fluid'}> {firstHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                    <div className={'text-start container-fluid'}> {secondHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
              </div>
      </div>
      </>
  )
}



export default function Funerals(props){

    const [photos,setPhotos] = useState([]);
    const [limit,setLimit] = useState(4);
    const [isEmpty,setEmpty] = useState("Loading Images");
    const [loginmsg, setLogingmsg] = useState("");
    const [colors, setColors] = useState("");
    const [logging, setLogging] = useState(false);
    const [diaglogueID, setdiaglogueID] = useState("msg16");

    useEffect(()=>{

    //Get all photos
    setLogingmsg("Getting Photos " + props.name +  " ...... ");
    setColors(" text-white bg-secondary");
    try {
      document.getElementById(diaglogueID).style.display = "block";
    } catch (error) {
      console.log("element is not found");
    }
    setPhotos([]);
    axios.post(APIurl,{
        cmd: "getphotobyid",
        id:props.name

        } )
        .then((response) => {

        var res = response.data;



        if(!res["success"]){
          console.log("check this ",res);
          setLogingmsg(res["data"]);
          setColors("Could not send request");
          setEmpty("No photo found");
        }
        else{

            if(res["data"].length ==0){

                setEmpty("No photo found");
              }
              else{
                setEmpty("");
              }


          const images = [];

          res["data"].forEach(element => {

            let el = { original: BaseAPIurl + "/Gallery/" + element.img,
                       thumbnail: BaseAPIurl + "/Gallery/" + element.img};
            images.push(el);

          });
          setPhotos(images);
          document.getElementById(diaglogueID).style.display = "none";
        }

        })
        .catch((error) => {

        console.log(error);

        });
    },[props]);


    const items7 = [
      {title:"DOME SERVICES",
       menu:[" PRIVATE CHEF'S ","WAITERS","USHERS"," ASSISTANCE OF CHEF'S","MOBILE TOILETS","MOBILE FRIDGE"," VIP SECTION",
       " BABYLON - DECOR"," EGYPT - DECOR"," FLOWERS GIRLS"," HEARSE",
      "FAMILY CARS BMW","STORAGE","BODY COLLECTION","BODY PREPARING","SCREEN",
      "CHURCH TROLLEY","RED CARPET","TISSUES","BOTTLES OF WATER","BOTTLES OF JUICE"," SOFT DRINKS (CAPPY)",
  "DOVES","BREAK FAST","SINGLE COUCH","WHITE FLOORING","GOLD PHOENIX"," DRIPPING","A2 CANVAS PICTURES","SOUND SYSTEM",
  "CARPETING","PODIUM","STAGE DECOR","CANDLES AND STANDS","DIPHIRI","TLB","PULPIT",
"URN WATER BOILER","GROCERIES WITH VEGS","ALL EQUIPMENTS","MC PROFESSIONAL","DRUMS"]},

      {title:'Equipments',
      menu:[
         ' Food warmers (different)',
          'Steel work',
          'hand wash basic',
          'Panel',
          'Table Clothes',
          'Foam Plates' ,
          'Cutlery',
          'Packs',
          'spoons',
          'Serviette',
          'Tissue',
          'Tooth Picks',
          'serving table',
          'Disposable Plates',
          'Disposable Cups',
          'Disposal spoons',
          'Servers',
          'Mobile food',
          'Chafing',
          'Table'
      ]
      },
      {
          title: 'OFFERINGS',
          menu: [
             ' Peeling',
              'catering',
              'Decor (extra cost)',
              'Serving',
              'cleaning afterwards',
              'Buying groceries',
              'Catering equipment',
              'Serving station setup',
              'Cooking',
              'Serving attendees'
          ]
      }
      ];

    const funerals = [

        {
            title: "Casket ",
            menu: [
              "coffin casket",
              "hearse",
              "2 family cars",
              "storage",
              "tents and chairs",
              "catering team",
              "equipment",
              "removed, collected, mortuary services",
              "programs",
              "Grave name",
              "fresh flowers",
              "funeral arrangements",
              "grave setup",
              "church trolley",
              "screen",
              "tissue",
              "bottle of water",
              "bottles of juice",
              "Lowering devices",
              "documents processing"
            ]
        },
        {
          title: "Funeral policy ",
          menu: [
           " Sound extra",
          "  Brass band",
            "Violins",
           " Clap and tap",
          "  Choir",
            
          "  Decor",
           " Exodus R59",
           " Leviticus R70",
            "Deuteronomy R90",
            
            "Diphiri",
            "Bus",
            "Inkuni",
          ]
      },
    ];



    return(<>

{logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}

    <div className='container-fluid m-0 p-2 bg-white'>


        <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
            <div className='h3'>
                <Button name= {props.name + " Gallery"} styles = "h3"/>
            </div>
        </div>
              
    <div className={'container-fluid'}>
      <hr />
      <div className='container my-2 mx-auto px-1 py-1'>
      In order to arrange a unique funeral services for each bereaved family our professional and well trained personnel see to every  details no matter how large or small
      </div>

{items7.map((item, index)=><Card_2 title={item.title} menu={item.menu} key={index}/>)}
</div>

        <div className='container-fluid mx-0 my-3 p-0 text-center'>
            <h4>Casket Services</h4>
            <div className='text-left'>
            Storages of body preparation of the body casket coffin and spray. We provide all tools necessary to get the jobs done our team will assist with grave closure and placing of wreaths. We do all setup of decor all grave side. We strive to be the leader in the funeral services industry
            </div>
            <h3 className='text-danger'>{isEmpty}</h3>
        </div>



    <div className='container-fluid mx-0 my-3 p-0'>
           {
               funerals.map((item, index)=><Cards key={index} title={item.title} menu ={item.menu}/>)
           }

       </div>

        <div className='container-fluid mx-0 my-3 p-0 text-center'>
            <h4>Photos</h4>
            <h3 className='text-danger'>{isEmpty}</h3>
        </div>



        <ImageGallery autoPlay= {true} items={photos} />

        <ButtonSquare name="Join Our Funeral Services" Icon="plus" />
    </div>

    </>)

}
