import Button from '../../components/ButtonPink';
import Footer from "../Footer";
import Img1 from '../../assets/img/meat1.jpg'
import Img2 from '../../assets/img/meat2.jpg'
import Img3 from '../../assets/img/meat3.jpg'
import Img4 from '../../assets/img/meat4.jpg'
import prices from '../../assets/img/PriceList.png'


const imageCards = [
    {
        image:Img1,
        body:'Mogodu'
    },
    {
        image:Img3,
        body:'Beef'
    },
     {
        image:Img2,
        body:'Chicken'
    }

]

const pars = [
    'Group Society/ Stokvel are allowed to join this policy for grocery or for funeral purposes, you can join as group to get certain things or amount of money ',
    'All our funeral packages are generally inclusive of quality food and essential groceries required for a funeral services and after'
]
function Card_2(props){
    const count = props.menu.length/2;
const items = props.menu;
const firstHalf = items.slice(0, count);
const secondHalf = items.slice(count, count * 2);

    return (
        <>
      <div className="card  my-3 p-0 container-fluid " >
                <div className="card-header bg-pink text-white text-center">
                  <b>{props.title}</b>
                </div>
                <div className=" card-body text-left go-flex">
                    <div className={'text-start container-fluid'}> {firstHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                      <div className={'text-start container-fluid'}> {secondHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                </div>
        </div>
        </>
    )
}

function ImageCard(props){
   const item = props.item;

  return ( <div className={'card card-width-33 rounded my-3 justify-content-around mx-auto'}>
      <img src={item.image} className={'card-img-top height-vp-60 cover'} alt={''}/>
      <div className={'card-body bg-secondary text-white'}>
          <h3 className={'card-title'}>
              {item.body}
          </h3>
      </div>

   </div>)

}
export default function MeatPolicy() {

    const items = [

        {
            title: "Less than 1 year old",
            menu: [
                'Hearse','Body Coffin','Body Preparer','Grave Side','Flowers'
            ]
        },
          {
            title: "1 to 4 years old",
            menu: [
                'Hearse','Family Car','Body Coffin','Grave Side','Flowers'
            ]
        },
         {
            title: "5 years and older",
            menu: [
                'Services','Hearse','Family Car','Coffin','Grave side','Flowers'
            ]
        },


    ];
    const items2 = [
        {title:"Meat Policy",
        menu:["6 members R120","8 members R150","14 members R200",
        "Single R40","Couples R45","Family plan R50"]},

        {title:'Extended Family',
        menu:[
          '6 Family (R120)',
            '8 Family (R150)',
            '14 Family (R200)',
            'Single (R40)',
            'Couple (R60)',
            'Family (R70)'
        ]
        }

        ];

    return (<>
        <div className='container-fluid mx-0 mb-4 p-2 bg-white text-center'>
            <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
                <div className='h3 animate__animated animate__fadeInDown'>
                    <Button name="Meat Policy" styles="h4" />
                </div>
            </div>
            <div className={'container-fluid row p-0 m-0'}>
                <img src={prices} className = "col-sm-6 mx-auto my-2 p-0"  alt = "prices" />

            </div>


     <div className={'container-fluid'}>

            {items2.map((item, index)=><Card_2 title={item.title} menu={item.menu} key={index}/>)}
    </div>
    <div className='container-fluid px-2 py-2 text-white bg-secondary text-left rounded'>
        <p>
        Proud to let you know that we have covers for group schemes,burial societies and 
        large groups.remember that we are a modernised and authentic funeral parlour,as such 
        we use only caskets for our burials.we have a R15 000 burial service plan for everyone 
        that also gives out a R5000 cash back as a token of condolences from us to your family 
        and a pre-funeral benefit of two 20L buckets of baked scones,tea making material and a big
         Oros juice for less than R400 per family per month.kindly contact us for this enormous benefit
         at a ridiculously low premium and we will serve you with pride,dignity and respect
        </p>
    </div>

              <div className={'container-fluid m-0 p-0 d-flex flex-wrap flex-row'}>
                {
                    imageCards.map((imageCard, index)=><ImageCard item={imageCard}/>)
                }
            </div>




       <div className='container-fluid mx-0 my-2 p-0 d-flex flex-wrap justify-content-around manage-services'>

            </div>







            <div className={'container-fluid'}>
                <div className={'card rounded'}>
                    <img className={'card-img height-vp-60 cover'} src={'https://images.unsplash.com/photo-1602470520998-f4a52199a3d6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fG1lYXR8ZW58MHx8MHx8&auto=format&fit=crop&w=2000&q=60'}/>
                    <div className={'container-fluid bg-op card-img-overlay text-white text-center d-flex flex-column justify-content-center py-3'}>
                        {
                            pars.map((par, index)=> <h4 key={index} className="m-0 py-1 px-0">
                                {par}
                            </h4>)
                        }
                    </div>
                </div>

            </div>




            {/*  <div className={'card-img-top img-fluid container-fluid cover m-0 p-0 '}>*/}
            {/*    <ImageGallery className={'card-50 height-vp-60'}*/}
            {/*        items={images} autoPlay={true} showNav={false}*/}
            {/*        showThumbnails={false} fullscreen={true}*/}
            {/*        showFullscreenButton={false} showPlayButton={false}*/}
            {/*        showBullets={true} showIndex={true}*/}
            {/*        slideDuration={500} slideInterval={3000}*/}
            {/*    />*/}
            {/*</div>*/}


        </div>
        <Footer />
    </>)

}
