import React,{useEffect,useState} from 'react';
import './index.scss';
import Button from '../../components/ButtonPink';
import ButtonSquare from '../../components/ButtonSquare';
import { APIurl,rootUrl,BaseAPIurl } from '../../App';
import axios from 'axios';
import MSGDialogue from '../../components/Dialogue';
import ImageGallery from 'react-image-gallery';


function Cards(props){

    return (
        <>
      <div className="card my-2 card-20" >
                <div className="card-header bg-pink text-white text-center">
                  <b>{props.title}</b>
                </div>
                <div className="card-body">
                   {props.menu.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink'></i> {item} <br/></span>)}
                </div>
        </div>

        </>
    )
}

function Cards_2(props){

    return (
        <>
      <div className="card my-2 card-18" >
                <div className="card-header bg-pink text-white text-center">
                  <b>{props.title}</b>
                </div>
                <div className="card-body">
                   {props.menu.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink'></i> {item} <br/></span>)}
                </div>
        </div>

        </>
    )
}


export default function Food(props){

    const [photos,setPhotos] = useState([]);
    const [limit,setLimit] = useState(4);
    const [isEmpty,setEmpty] = useState("Loading Images");
    const [loginmsg, setLogingmsg] = useState("");
    const [colors, setColors] = useState("");
    const [logging, setLogging] = useState(false);
    const [diaglogueID, setdiaglogueID] = useState("msg16");

    useEffect(()=>{

    //Get all photos
    setLogingmsg("Getting Photos " + props.name +  " ...... ");
    setColors(" text-white bg-secondary");
    try {
      document.getElementById(diaglogueID).style.display = "block";
    } catch (error) {
      console.log("element is not found");
    }
    setPhotos([]);
    axios.post(APIurl,{
        cmd: "getphotobyid",
        id:props.name

        } )
        .then((response) => {

        var res = response.data;



        if(!res["success"]){
          console.log("check this ",res);
          setLogingmsg(res["data"]);
          setColors("Could not send request");
          setEmpty("No photo found");
        }
        else{

            if(res["data"].length ==0){

                setEmpty("No photo found");
              }
              else{
                setEmpty("");
              }


          const images = [];

          res["data"].forEach(element => {

            let el = { original: BaseAPIurl + "/Gallery/" + element.img,
                       thumbnail: BaseAPIurl + "/Gallery/" + element.img};
            images.push(el);

          });
          setPhotos(images);
          document.getElementById(diaglogueID).style.display = "none";
        }

        })
        .catch((error) => {

        console.log(error);

        });
    },[props]);

    const foodMenu = [
                        {title:"Salad / Veggies", menu:["Roasted Veggies","Cornslow","Beetroots","Sweet potatoes","Chakalaka","Celery salad","Green salad"]},
                    {title:"Starch", menu:["Rices","Pap","Samp"]},
                    {title:"Meats", menu:["Oxtail","Fried chicken"]},
                    {title:"Drinks", menu:["Water bottles","Juices"]}];

    const dome = [{title:"Morning Session", menu:["Five roses tea","Rooibos Tea","Coffee ","Cream of coffee","Fresh Milk","Scones","Bread","Sandwich","Rama & Sugar"]},
    {title:"Salad / Veggies", menu:["Chakalaka","Broccolli","Goat Cheese","Salad Mayo","Roast potatoes","Betta Nuts creame ","Cabagge Peas","Cheese Rose","Beetroots","Shatini","Celery Salad","Creek Salad","Arugula","Mixed Dressing"]},
{title:"Starch", menu:["Rices","Pap","Stamp","Dumpling","Ting"]},
{title:"Meats", menu:["Beef Stew","Chicken Stew","Roasted/Fried chicken","Beef Stew","Fish"]},
{title:"Drinks", menu:["Water bottles","cold Drinks","Juices","Gemere"]}];
    return(<>

{logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}

    <div className='container-fluid m-0 p-1 bg-white'>

        <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
            <div className='h3'>
                <Button name= {props.name + " Gallery"} styles = "h3"/>
            </div>
        </div>
        <div className='container-fluid mx-0 my-3 px-0'>
            <h3> 1. Menu for Casket plan services</h3>
            <div className='container-fluid px-0 mx-0 my-3 d-flex justify-content-around foods-photos'>
            {foodMenu.map((slide,index) => <Cards key={index} title={slide.title} menu={slide.menu} />)}
            </div>
        </div>

        <div className='container-fluid mx-0 my-3 px-0'>
            <h3> 2. Menu for Dome plan Services</h3>
            <div className='container-fluid px-0 mx-0 my-3 go-flex  foods-photos'>
            {dome.map((slide,index) => <Cards_2 key={index} title={slide.title} menu={slide.menu} />)}
            </div>
        </div>

        <div className='container-fluid mx-0 my-3 p-0 text-center'>
            <h4>Photos</h4>
            <h3 className='text-danger'>{isEmpty}</h3>


        </div>

        <ImageGallery autoPlay= {true} items={photos} />

        <ButtonSquare name="Join Our Funeral Services" Icon="plus" />
    </div>

    </>)

}
