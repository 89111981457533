import './index.scss'
import { useState, useEffect } from 'react'
import MissionSliderComponent from './Component'
import MissionBG from '../../assets/img/mission.jpg'
import VisionBG from '../../assets/img/vision.jpg'


function MissionSlider() {

  var count = 0;
  const MisionSliding = (index) => {
    count = count + index;

    let el = document.getElementsByClassName("mission-card");

    for (let i = 0; i < el.length; i++) {
      el[i].style.display = "none";
    }
    if (count >= el.length) {
      count = 0;

      el[count].style.display = "block";
    }
    else if (count < 0) {
      count = el.length - 1;
      el[count].style.display = "block";
    }
    else {
      el[count].style.display = "block";
    }

  }

  useEffect(() => {

    const intervalslide = setInterval(() => { MisionSliding(1) }, 10000);
    return () => clearInterval(intervalslide);

  }, []);


  const slides = [
    {
      title: "Our Mission",
      display: "",
      controls: ["active", "inactive"],
      image: MissionBG,
      description: 'We are a company that aims to assist our communitues in time of need by offering affordable Premium to bury your loved one with difnity. One thing about us we bring you comfort and solace when you need it the most. '
    },

    {
      title: "Our Vision",
      controls: ["inactive", "active"],
      display: "none",
      image: VisionBG,
      description: 'Our Funeral cover help you with certain thing or services to bury your loved one. We offer better services to our clients depending on what you need on our plans we have a range option to suit your need plans. we can make a product to suite your need, from funeral services, catering, policies, grocery, cow plan, tombstone, meat benefits, live stock and even cremation or grave benefit. If its funeral related we can make it work '
    }

  ]

  return (
    <div className="container-fluid my-3 mission-slider position-relative top-0">

      <div className="left-control shadow hover bg-dark" onClick={()=>MisionSliding(-1)}><i className='fa fa-angle-left'></i></div>
      <div className="right-control shadow hover bg-dark" onClick={()=>MisionSliding(1)}><i className='fa fa-angle-right'></i></div>
      <div className='container-fluid my-0 p-0 d-flex justify-content-center mx-auto'>

        {slides.map((slide, index) => <MissionSliderComponent key={index} onClick={MisionSliding} display={slide.display} controls={slide.controls} title={slide.title} description={slide.description} bg={slide.image} />)}

      </div>



      <div className='line'>
        <hr className='bg-secondary' />
      </div>

    </div>




  )
}

export default MissionSlider