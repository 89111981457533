import React from "react";
import "./index.scss";
import Dmenu from "../../components/Dmenu";
import WhatsappIcon from "../../components/WhatsappIcon";
import Logo from "../../assets/img/colored-logo.png";
import safrican from "../../assets/img/safrican-removebg-preview.png";

const listmenu = [
  {
    name: "Home",
    icon: "fa fa-home",
    link: "home",
    delay: 0.1,

    hasSubmenu: false,
    submenu: [],
  },
  {
    name: "Our Services",
    icon: "fa fa-dove",
    closeBar: "",
    delay: 0.2,
    link: "#",
    hasSubmenu: true,
    submenu: [
      { name: "Funeral Services", href: "/funeralservices" },
      { name: "Catering Services", href: "cateringservices" },
      { name: "Policy Conditions", href: "policyconditions" },
      { name: "Any Kind of Event", href: "anykindservices" },
    ],
  },
  {
    name: "Brochure Plans",
    icon: "fa fa-book",
    closeBar: "",
    link: "#",
    delay: 0.3,

    hasSubmenu: true,
    submenu: [
      { name: "Catering Policy", href: "/cateringPolicy" },
      { name: "Funeral Policy", href: "/funeralPolicy" },
      { name: "Cow Policy", href: "/cowPolicy" },
      { name: "Grocery Policy", href: "/groceryPolicy" },
      { name: "After tears  Policy", href: "/afterTearsPolicy" },
      { name: "After care Policy ", href: "/afterCarePolicy" },
      { name: "Meat Policy", href: "/meatPolicy" },
      { name: "Tombstone Policy", href: "/tombstonePolicy" },
    ],
  },
  {
    name: "Gallery",
    icon: "fa fa-image",
    closeBar: "",
    delay: 0.4,
    link: "#",
    hasSubmenu: true,
    submenu: [
      { name: "Coffins", href: "coffins" },
      { name: "Decorations", href: "decorations" },
      { name: "Foods ", href: "foods" },
      { name: "Drinks", href: "drinks" },
      { name: "Funerals", href: "funerals" },
      { name: "Catering", href: "catering" },
      { name: "Grocery List", href: "grocery" },
    ],
  },
  {
    name: "About Us",
    icon: "fa fa-user",

    delay: 0.5,
    link: "/about",
    hasSubmenu: false,
    submenu: [],
  },
  {
    delay: 0.6,
    name: "Videos",
    icon: "fa fa-video",

    link: "videos",
    hasSubmenu: false,
    submenu: [],
  },
  // {
  //     name: "Call Me Back",

  //     delay: 0.7,
  //     icon: "fa fa-phone",
  //     link: "",
  //     hasSubmenu: false,
  //     submenu: [
  //     ]

  // },

  {
    name: "Self Services",

    delay: 0.9,
    icon: "fa fa-laptop",
    link: "register",
    hasSubmenu: false,
    submenu: [],
  },
];

export default function Header() {
  const openBar = () => {
    document.getElementById("side-nav").style.display = "block";
  };

  return (
    <>
      <div className="header p-0 m-0 container-fluid">
        <div className="navBar m-0 p-0">
          <div className="logo py-1">
            <img src={Logo} alt="Logo" height="50px" />
          </div>
          <div className="slogan m-0 p-0 d-hide">
            <span className="text-secondary p-1">
              {" "}
              <b>
                {" "}
                <span className="text-nowrap">Underwritten by RMA</span> <br />
              </b>
            </span>
          </div>
          <span className="d-hide menu-bars">
            <i className="fa fa-bars text-black m-1" onClick={openBar}></i>
          </span>
          <div className="p-hide d-menu m-0 text-black">
            <Dmenu items={listmenu} />
          </div>
        </div>

        <div className="w-icon">
          <WhatsappIcon phone="+27607625627" />
        </div>
      </div>
    </>
  );
}
