import Button from '../../components/ButtonPink';
import Footer from "../Footer";
import Img1 from '../../assets/img/aftertears 1.jpg'
import Img2 from '../../assets/img/aftertears 3.jpg'
import Img3 from '../../assets/img/aftertears 2.jpg'
import Img4 from '../../assets/img/policy4.jpg'
import Img5 from '../../assets/img/policy5.jpg'
import ImageGallery from "react-image-gallery";

const images = [
    {
        original:Img1,
        thumbnail:Img1
    },
    {
        original:Img2,
        thumbnail:Img2
    },
 {
        original:Img3,
        thumbnail:Img3
    },
   {
        original:Img4,
        thumbnail:Img4
    },
 {
        original:Img5,
        thumbnail:Img5
    },
];
const plans1 = [

];

function PlansCard(props){

    return (<>
     <div className='col-sm-4 p-1 m-0'>
                        <div className='card text-left'>
                            <div className='card-title bg-pink text-white'>
                                <h3 className='text-center'>{props.title}</h3>
                            </div>
                                <div className='card-body'>
                                    {props.list.map((item,index)=><span  key={index}><i className='fa fa-circle-check text-pink '></i> {item}<br/></span>)}
                                
                                <hr />
                                <h4>Premium</h4>
                                <ul className='text-secondary'>
                                    {props.pre.map((item,index)=>  <li key={index}>{item}</li>)}
                                </ul>
                            </div>
                           
                        </div>
                    </div>
    </>)

}
function Cards(props){
    const count = props.menu.length/2;
const items = props.menu;
const firstHalf = items.slice(0, count);
const secondHalf = items.slice(count, count * 2);

    return (
        <>
      <div className="card  my-3 p-0 container-fluid card-30" >
                <div className="card-header bg-pink text-white text-center">
                  <b>{props.title}</b>
                </div>
                <div className=" card-body text-left ">
                    <div className={'text-start container-fluid'}> {items.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                </div>
        </div>
        </>
    )
}

function ImageCard(props){
    const item = props.item;
 
   return ( <div className={'card card-45 rounded my-3 text-left'}>
       <img src={item.image} className={'card-img-top height-vp-60 cover'}/>
       <div className={'card-body bg-secondary text-white'}>
           <p>
               {item.body}
           </p>
       </div>
 
    </div>)
 
 }

const str1 = "Planning ahead provides peace of mind to all involved. Planning ahead is a gift of love that brings great peace of mind both to you and loved ones.  Planning ahead gives you time to create a meaningful and healthy celebration of life.   A thoughtfully planned funeral service is a healthy first step for the bereaved on their individual grief journey.";
const str2 ="Planning ahead is a gift of love that bring the day of your/ your family's burial carries one of the memories that your family will remember forever. It must be a day where things are done properlys great peace of mind... Let us help you with your funeral Plan today.Choose wisely, choose Family...it is worth more than the money it will cost you.";
const imageCards = [
    {
        image:Img1,
        body:str1
    },
    {
        image:Img2,
        body:str2
    }

]

export default function AfterTearsPolicy() {


    const items = [

        {
            title: "Use the cash for:",
            menu: [
                'After care (beers) ','Meat cutting','Electricity','Transport','Graves yard cost','unveiling '
            ]
        },
        {title:'After Tears',menu:["Single R28","Couples R46","Family plan R69",
        "6 members R154","8 members R189",
        "10 members R203","14 members R292"]}

    ];

    return (<>
        <div className='container-fluid mx-0 mb-4 p-1 bg-white text-center'>
            <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
                <div className='h3 animate__animated animate__fadeInDown'>
                    <Button name="After Tears Policy" styles="h4" />
                </div>
            </div>

            
    <div className='container-fluid mx-0 my-3 p-0 '>
          <div className="card my-2 mx-1 " >
                <div className="card-header bg-secondary text-white text-center">
                <b>This cash policies a clients could use it for the following </b>
                </div>

        </div>

         <div className=" text-left  d-pad-4 go-flex mx-2 ">
                    {items.map((item, index)=><Cards title={item.title} menu={item.menu} key={index}/>)}
                </div>

       </div>

             <hr className="mb-3 mt-0 mx-auto w-90 bg-secondary border-dark" />



       <div className='container-fluid mx-0 my-2 p-0 d-flex flex-wrap justify-content-around manage-services'>

            </div>

            <hr className="my-2 mx-auto w-90 bg-secondary border" />

            <div className={'container-fluid m-0 p-0fluid go-flex'}>
                {
                    imageCards.map((imageCard, index)=><ImageCard item={imageCard}/>)
                }
            </div>
        </div>
        <div className='container-fluid px-2 '>
            
        <div className='container-fluid px-2 py-2 text-white bg-secondary text-left rounded '>

Remember the thought you always have of what will happen (Financially) if you can loose your loved one? That thought is depressing and always robs you of your peace... For peace of mind and comfort, contact us via whatsApp or call to hear about the plans that we have which are within your budget...


 </div>

        </div>
        <Footer />
    </>)

}
