import './index.scss'
import DLogo from '../../../assets/img/logo1.jpg';

function Components() {

    return (
        <div className={'d-flex justify-content-center  mx-auto all-wrapper'}>
        <div className='main-im'>
            <div className='image-container' style={{
                // backgroundImage: { DLogo },
            }}>
                <img className="d-logo" src={DLogo}>

                </img>


            </div>
            <div className='slogan-container px-3 py-1 bg-pink text-white rounded shadow'>
                <h4 >
               "Be courageous and strong trust in jehovah (GOD)"
                </h4>
            </div>

        </div>
        </div>


    );

}

export default Components;