import Button from '../../components/ButtonPink';
import './index.scss'
import { APIurl, rootUrl, BaseAPIurl } from '../../App';
import axios from 'axios';
import { useEffect, useState } from 'react';
import home from '../../assets/img/HomeChoice-logo.png';


function Videos(props){
    const [caption1, setCaption] = useState("");
    const [shorten, setShorten] = useState(true);
    var text = "";
    var i =0;
    while(text.length<50){

        if(i>=props.caption.length){
            break;
        }
        else{
            text = text +  props.caption[i];
            i++;
        }
       
    }
  
const ReadMore = ()=>{
    setShorten(false);
  setCaption(props.caption);
}

useEffect(()=>{
   
   
    if(props.caption.length<50){

        setShorten(false);

    }
    else{
        text = text + "...";
    }
    setCaption(text);
    
},[]);

    return (
        <>
        <div className="card my-2 mx-2 rounded animate__animated  animate__zoomIn" >
        <video className='card-img-top' width="320" height="240" controls>
        <source src={BaseAPIurl + "VideosFashion/" + props.video} type="video/mp4"/>
                 Your browser does not support the video tag.
        </video>
            <div className="card-body p-1 bg-pink text-white text-left">
            <h5 className='animate__animated animate__fadeInUp'>{props.title}</h5>
               <p className='animate__animated animate__fadeIn animate__slower'>
                    {caption1}{shorten? <div onClick={ReadMore} className=' p-1 mx-1 my-0 small bg-secondary text-warning bg-white border rounded'>Read more+</div> : ""}
               </p>
            </div>
        </div>
        </>
    )
}

function FashionPage() {


    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);

useEffect(()=>{

    const imagesUrl = 'https://gpsfuneralhome.co.za/fashion/';
    axios.post(APIurl, {
        cmd: "getallcloths",

    })
        .then((response) => {

            const data = response.data.data;

            const finalData = data.map((image) => imagesUrl + image.img)
            //console.log(finalData)
            setImages(finalData.reverse())

        });

        axios.post(APIurl, {
            cmd: "getallvideosfashion",
    
        })
            .then((response) => {
    
                const data = response.data.data;
                setVideos(data.reverse())
               
    
            });
    

},[]);

    return (
        <>
            <div className='container-fluid mb-4 px-2 bg-white m-0 '>

                <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
                    <div className='h3 animate__animated animate__fadeInDown'>
                        <Button name="Boutique Cloths" styles="h4" />
                    </div>
                </div>

                <h3 className='text-gray'>Clothes for:</h3>

                <div className=' text-gray mt-4 mb-4'>
                    <h4>1. Men</h4>
                    <h4>2. Children/Kids</h4>
                </div>
                <hr className="my-2 mx-auto w-95 border" />

                <div className='text-white container rounded bg-secondary text-white px-2 py-2 my-3'>
                        Small, privately owned shops that offer upscale products such as clothes, jewelry, and shoes. <br/>
                        Our great collection of Fashion items for men and kids.
                </div>

                <div className='text-black border shadow  rounded'>
                    <ul className='mx-0 mt-2'>
                        <li className='h6'>
                            Belts, Shirts and Formal shoes
                        </li>
                    </ul>
                </div>
                <div className='container my-3'>
                    <h4> You can order home-choice from us</h4>
                    <div className='row' >
                        <div className='col-sm-3'>
                            <img src={home} alt="home choice logo " className='mx-auto' />
                        </div>
                    </div>

                </div>
                 
        <div className='container-fluid mx-0 my-3 p-0 text-center'>
     
            <div className='container-fluid mx-0 my-2 p-0 d-flex flex-wrap justify-content-around'>
            {videos.map((slide,index) => <Videos key={index} id={index} title="Watch" caption="Check our available fashions here" video={slide.img}/>)}

            </div>
           
        </div> 



                <div className='container-fluid card my-2 p-2'>

                    {images.map((cloth, index) => {

                        if (index % 2 === 0) {

                            return <div key={index} className='d-flex flex-row wrapper my-2 mx-0 p-0 animate__animated animate__fadeInUp' style={{
                                "animationDelay": `0.${index + 2}s`
                            }}>

                                <img  src={images[index]} className="clothes-image card mx-auto shadow" alt="..." />

                                <div className='spacer'> <h1></h1></div>


                                {
                                    images[index + 1] ?

                                        <img src={images[index + 1]} className="card clothes-image mx-auto shadow" alt="..." />
                                        : null
                                }

                            </div>

                        }




                    })
                    }



                </div>



            </div>



        </>
    )
}

export default FashionPage; 