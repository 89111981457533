import React from 'react';
import "./index.css";
import {Link} from "react-router-dom";

function MenuItem(props){
    let icon = "";
    let show = "d-none";
    if(props.list.length>0){
        icon = "angle-down";
        show = "";
    }
    return (
        <>
        <div className=" m-0 d-menu-item px-2  mx-2 d-flex flex-column justify-content-center h-100  rounded">
            <Link className={'hover text-pink text-decoration-none'} to={props.href}> 
            <b className="hover p-0 m-0"> {props.title}  <i className={"fa fa-" + icon} /> </b> 
             <div className="underline bg-pink p-0 m-0" ></div> 
            </Link>
           
        
            <div className={"drop-down-list bg-dark text-white p-2 rounded " + show }>
                <ul>
                {props.list.map((item,index) => <Link key={index} to={item.href} className={'hover text-white text-decoration-none route-link'}><p key={item.name} className="hover" >{item.name}</p></Link>)}
                </ul>
            </div>
        
        </div>
        
        </> 
    )
};


export default function Menu(props){
    return (
        <>

        <div className="d-flex text-black pt-3">

        {props.items.map((item) => <MenuItem key={item.delay} list={item.submenu} title={item.name} href={item.link} />)}

        </div>

        </>
    )

}