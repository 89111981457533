import React, { useRef } from 'react';
import './index.scss';
import homechoice from '../../assets/img/HomeChoice.jpg';
import Royal from '../../assets/img/Royal-logo.png';
import Havana from '../../assets/img/Havana-Logo.png';
import Working1 from '../../assets/img/workingWith1.jpg';
import Working2 from '../../assets/img/workingWith2.jpg';
import Working3 from '../../assets/img/workingWith3.jpg';



function Client(props) {
  return (
    <>
      <li className='p-0 ml-2 rounded'><a href={props.href} className='rounded p-0'> <img src={props.src} className="rounded" /></a></li>
    </>
  )

}


export default function Our_clients() {
    
  const Clients = [
    { id: 1, href: 'https://www.homechoice.co.za/', src: homechoice },
    { id: 2, href: 'https://royaltombstones.co.za/', src: Havana },
    { id: 6, href: 'https://havanalife.co.za/', src: Royal },
    { id: 3, href: '', src: Working1 },
    { id: 4, href: '', src: Working2 },
    { id: 5, href: '', src: Working3 }
  
  ];

    return (
      <>
      <div className='pl-2 our-client container' >
       
        <div className='container-fluid m-0 pl-2 d-flex flex-direction-row justify-content-around  our-client-container'>
          <div className='text-left bob m-auto  animate__animated animate__fadeInUp'><b>Working with</b> <i className="fa fa-angle-down py-0 my-0 mx-3 "> </i>  </div>
          <div className='our-client-slide m-0 p-0 animate__animated animate__fadeInUp'>
            <ul>
              {Clients.map((Clients) => <Client key={Clients.id} href={Clients.href} src={Clients.src} />)}
            </ul>
          </div>
        </div>
        <hr className='line w-100' />
        </div>
      </>
    )
  
     

  
}
