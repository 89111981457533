import { useEffect } from 'react';
import SideNavMenuItem from './Component';
import './index.scss'
import { Outlet, Link, Navigate } from "react-router-dom";

import { fontAwesome } from 'fontawesome';

const closeBar = () => {

    document.getElementById("side-nav").style.display = "none";
}

const MakeCall = () => {

    document.getElementById("side-nav").style.display = "none";
    document.location.href="tel:*140*+27607625627#";
}

const items = [
    {
        name: "Home",
        icon: "fa fa-home",
        link: "home",
        delay: 0.1,
        closeBar: closeBar,
        hasSubmenu: false,
        submenu: []
    }, {
        name: "Our Services",
        icon: "fa fa-dove",
        closeBar: "",
        delay: 0.2,
        link: "#",
        hasSubmenu: true,
        submenu: [
            { name: 'Funeral Services', href: '/funeralservices' },
            { name: 'Catering Services', href: 'cateringservices' },
            { name: 'Policy Conditions', href: 'policyconditions' },
            { name: 'Any Kind of Event', href: 'anykindservices' }
        ]

    },
    {
        name: "Brochure Plans",
        icon: "fa fa-book",
        closeBar: "",
        link: "#",
        delay: 0.3,
        hasSubmenu: true,
        submenu: [
            { name: 'Catering Policy', href: '/cateringPolicy' },
            { name: 'Funeral Policy', href: '/funeralPolicy' },
            { name: 'Cow Policy', href: '/cowPolicy' },
            { name: 'Grocery Policy', href: '/groceryPolicy' },
            { name: 'After tears  Policy', href: '/afterTearsPolicy' },
            { name: 'After care Policy ', href: '/afterCarePolicy' },
            { name: 'Meat Policy', href: '/meatPolicy' },
            { name: 'Tombstone Policy', href: '/tombstonePolicy' }
        ]
    },
    {
        name: "Gallery",
        icon: "fa fa-image",
        closeBar: "",
        delay: 0.4,
        link: "#",
        hasSubmenu: true,
        submenu: [
            { name: 'Coffins', href: 'coffins' },
            { name: 'Decorations', href: 'decorations' },
            { name: 'Foods ', href: 'foods' },
            { name: 'Drinks', href: 'drinks' },
            { name: 'Funerals', href: 'funerals' },
            { name: 'Catering', href: 'catering' },
            { name: 'Grocery List', href: 'grocery' }

        ]

    },
    {
        name: "About Us",
        icon: "fa fa-user",
        closeBar: closeBar,
        delay: 0.5,
        link: "/about",
        hasSubmenu: false,
        submenu: [
        ]

    },
    {
        delay: 0.6,
        name: "Videos",
        icon: "fa fa-video",
        closeBar: closeBar,
        link: "videos",
        hasSubmenu: false,
        submenu: [
        ]
    },
    {
        name: "Call Me Back",
        closeBar:MakeCall,
        delay: 0.7,
        icon: "fa fa-phone",
        link: "",
        hasSubmenu: false,
        submenu: [
        ]

    },
    {
        delay: 0.8,
        closeBar: closeBar,
        name: "How to Pay",
        icon: "fab fa-cc-mastercard",
        link: "/payment",
        hasSubmenu: false,
        submenu: [
        ]

    },
    {
        name: "Self Services",
        closeBar: closeBar,
        delay: 0.9,
        icon: "fa fa-laptop",
        link: "register",
        hasSubmenu: false,
        submenu: [
        ]

    }
]


function SideNav() {
    useEffect(() => {

    }, []);

    return (
        <div className="side-nav d-hide animate__animated animate__fadeInLeft" id="side-nav">

            <div className='d-flex justify-content-end py-0 px-2 m-0'>
                <span className="close-btn m-0 p-0"><i className="fas fa-times text-danger h3" onClick={closeBar} ></i>
                </span>
            </div>



            <div className='body-wrapper'>
                <div className='body-wrapper'>
                    <div className="side-nav-body bg-black">
                        {items.map((item, index) =>
                            <SideNavMenuItem  onClick={closeBar} item={item} key={index} />
                        )}
                    </div>
                    <div className='my-2 d-flex flex-row justify-content-around container-fluid ' >
                            <Link onClick={closeBar} to="/fashion"  className="text-pink btn shadow border learn-more my-2 animate__animated animate__fadeInUp animate__slower" ><b>Learn more +</b></Link>
                        </div>
                    <div className='conatiner-fluid w-100 mx-0 side-nav-footer position-absolute bottom-0 left-0 px-3'>
                        <h3 className='text-black'>-- Contact</h3>
                        <div className='container-fluid  mx-0 my-1 animate__animated animate__fadeInDown '>
                            <h4 className='text-secondary'><i className='fab fa-whatsapp text-success'></i>
                                +27 60 762 5627
                            </h4> 
                            <h4 className='text-secondary'> <i className='fa fa-phone text-success mr-2'></i>
                           (Calls):  010 634 2230 
                            </h4>
                        
                            <h4 className='text-secondary'> <i className='fa fa-phone text-success mr-2'></i>
                           (Consult):  013 170 5658
                            </h4>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}

export default SideNav;