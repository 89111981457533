import Button from '../../components/ButtonPink';
import Footer from "../Footer";
import Img1 from '../../assets/img/policy1.jpg'
import Img2 from '../../assets/img/policy2.jpg'
import Img3 from '../../assets/img/policy3.jpg'
import Img4 from '../../assets/img/policy4.jpg'
import Img5 from '../../assets/img/policy5.jpg'
import ImageGallery from "react-image-gallery";

const images = [
    {
        original:Img1,
        thumbnail:Img1
    },
    {
        original:Img2,
        thumbnail:Img2
    },
 {
        original:Img3,
        thumbnail:Img3
    },
   {
        original:Img4,
        thumbnail:Img4
    },
 {
        original:Img5,
        thumbnail:Img5
    },
]
function Cards(props){
    const count = props.menu.length/2;
const items = props.menu;
const firstHalf = items.slice(0, count);
const secondHalf = items.slice(count, count * 2);

    return (
        <>
      <div className="card  my-3 p-0 container-fluid card-30" >
                <div className="card-header bg-pink text-white text-center">
                  <b>{props.title}</b>
                </div>
                <div className=" card-body text-left ">
                    <div className={'text-start container-fluid'}> {items.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                </div>
        </div>
        </>
    )
}

function Card_2(props){
    const count = props.menu.length/2;
const items = props.menu;
const firstHalf = items.slice(0, count);
const secondHalf = items.slice(count, count * 2);

    return (
        <>
      <div className="card  my-3 p-0 container-fluid " >
                <div className="card-header bg-pink text-white text-center">
                  <b>{props.title}</b>
                </div>
                <div className=" card-body text-left go-flex">
                    <div className={'text-start container-fluid'}> {firstHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                      <div className={'text-start container-fluid'}> {secondHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                </div>
        </div>
        </>
    )
}

export default function FuneralPolicy() {


    const items = [

        {
            title: "Less than 1 year old",
            menu: [
                'Hearse','Body Coffin','Body Preparer','Grave Side','Flowers'
            ]
        },
          {
            title: "1 to 4 years old",
            menu: [
                'Hearse','Family Car','Body Coffin','Grave Side','Flowers'
            ]
        },
         {
            title: "5 years and older",
            menu: [
                'Services','Hearse','Family Car','Coffin','Grave side','Flowers'
            ]
        },


    ];



    const items2 = [

        {title:'Jude Plan (value of R25 000)',
        menu:[
           ' Casket (Redwood Quarter)',
            'Hearse',
            'Family cars',
            'programmes mortuary services',
            ' Removal & collect',
            'Tents & Chairs',
            'Tables',
            'Catering team',
            'Equipment (Cater)',
            'Grave name', 'Grave side',
            'grocery a vegs (R3000)',
            'Bottle water',
            'Bottle Juice',
            'Bus',
            'Tissues',
            'Sound system'
        ]
        },
        {
            title: 'Ezekiel Plan (services)',
            menu: ['Age 0-64 years',' Above 64 for an extra cost','We can still cover the old Person over ages']
        }
        ];

        const isiah = [

            {title:'Judah Plan',
            menu:[
               ' Caskets',
                'Full Services Including all document',
                'Coffin spray (Fresh Flower)',
                'Grave Borad',
                'Cemetry setup',
                'Programmes',
                'Hearse',
                'Family car',
                'Tent ( 7 by 10 ) 2 Pole',
                'Tables',
                'Chairs 50s'
                
            ]
            },
            {title:'Isaiah Plan value of R35 000',
            menu:[
               ' Casket (Teak)',
                'Hearse',
                'Family cars',
                'Mortuary Services',
                'programmes mortuary services',
                ' Removal & collect',
                'Body preper',
                'Programmes',
                'Tent & chairs',
                'Catering team',
                'Equipment (Cater)',
                'Grave name', 'Grave side',
                'Grade Side',
                'Sound System',
                'grocery ',
                'Bottle water',
                'Bottle Juice',
                'Bus',
                'Tissues',
                'Sound system'
            ]
            },
            {
                title: 'Revelation plan value of R20 000',
                menu: [ ' Casket (Kingston)',
                'Hearse',
                'Family cars',
                'Mortuary Services',
                'programmes mortuary services',
                ' Removal & collect',
                'Tables',
                'Programmes',
                'Tent & chairs',
                'Catering team',
                'Equipment (Cater)',
               
                'Grade Side',
                'Sound System',
                'grocery ',
                'Bottle water',
                'Bottle Juice',
                'Tissues',
                ]
            },
            {
                title: 'Timothy Plan/ Value of R30 000',
                menu: [ ' Casket (Halfview Reedwood)',
                'Hearse',
                'Family cars',
                'Mortuary Services',
                ' Removal & collect',
                'Tables',
                'Programmes',
                'Tent & chairs',
                'Catering team',
                'Equipment (Cater)',
               
                'Grade Side',
                'Sound System',
                'grocery & vegs ',
                'Bottle water',
                'Bottle Juice',
                'Tissues',
                'Body Preper',
                'Grave name',
                'Bus',
                'Toilet',
                'Flowers'
                ]
            }
            ];
    const items3 = [
        {
            title:'PRICING',
            menu:[
                'Single (M) [R100 p/m]',
                'Couples (Husband & Wife) [R150 p/m]',
                'Family (Husband & Wife + 6 children) [R200 p/m]',
                'Extended (6 Family Members) [R320 p/m]',
                'Extended (9 Family Members) [R400 p/m]',
                'Extended (14 Family Members) [R600 p/m]',
            ]
        }
    ];

    const items4 = [
        {
            title:'Our price plans',
            menu:[
                'Amalungaa Angu 1-5 [R200]',
                'Amalunga 1-9 [R300]',
                'Amalunga 1-12 [R400]',
                'Iminyaka for 0-64 wandlula uya add imali',
                'Asikhethi zibongo',
                'Silindisa inyanga ezinhlanu',
                'Akulindwa uma undlu ngengozi',
                'Uma udinga ibus nomgodi [R70]',
                'Mobile toilet & Mobile fridge [R100]'
            ]
        },
        ];

      const items5 = [
        {
            title:'Izinto  Ezenziwe Umasingcwabisane Kumalunga wethu',
            menu:[
                '(Casket) wonke umuntu',
                'Amakhaza',
                'Koloyi Kamufi',
                'Koloyi zomdeni',
                'Itende nezintulo',
                'Izandla',
                'Ukudla',
                'Amadlelo',
                'Uhlelo lomngcwabo',
                'Mngewabo wonke',
                'Zimbali',
                'Siphande semathuneni'

            ]
        },
        ]
    return (<>
        <div className='container-fluid mx-0 mb-4 p-1 bg-white text-center'>
            <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
                <div className='h3 animate__animated animate__fadeInDown'>
                    <Button name="Funeral Policy" styles="h4" />
                </div>
            </div>

            
    <div className='container-fluid mx-0 my-3 p-0 '>
          <div className="card my-2 mx-1 " >
                <div className="card-header bg-secondary text-white text-center">
                <b>Conditions For child Members Services Funeral</b>
                </div>

        </div>
  

         <div className=" text-left  d-pad-4 go-flex mx-2 ">
                    {items.map((item, index)=><Cards title={item.title} menu={item.menu} key={index}/>)}
                </div>

       </div>

             <hr className="mb-3 mt-0 mx-auto w-90 bg-secondary border-dark" />


            <div className='container-fluid mx-auto my-3 p-0 text-center go-flex'>
            <div className="card my-2 mx-1 rounded card-50" >


            <div className={'card-img-top height-vp-50 cover '}>
                <ImageGallery
                    items={images} autoPlay={true} showNav={false}
                    showThumbnails={false} fullscreen={true}
                    showFullscreenButton={false} showPlayButton={false}
                    showBullets={true} showIndex={true}
                    slideDuration={500} slideInterval={3000}
                />
            </div>

            <div className={'container-fluid card-body m-auto p-1 bg-secondary text-white text-center z-index-9'}>

                <p>
                    Our personel take care of the family with respect and account dignity taking into account their grief and trauma.
                </p>
                <p>
                    Even the pensioners, we can accommodate your age if you give us a chance include them.
                </p>
            </div>

                 </div>
            </div>

       <div className='container-fluid mx-0 my-2 p-0 d-flex flex-wrap justify-content-around manage-services'>

            </div>

            <hr className="my-2 mx-auto w-90 bg-secondary border" />

          <div className={'container-fluid'}>

            {items2.map((item, index)=><Card_2 title={item.title} menu={item.menu} key={index}/>)}
    </div>

               <hr className="mb-3 mt-0 mx-auto w-90 bg-secondary border color-theme" />

            <div className={'container-fluid'}>

            {items3.map((item, index)=><Card_2 title={item.title} menu={item.menu} key={index}/>)}
    </div>

                        <hr className="mb-3 mt-0 mx-auto w-90 bg-secondary border color-theme" />

            <div className={'container-fluid'}>

            {items4.map((item, index)=><Card_2 title={item.title} menu={item.menu} key={index}/>)}
    </div>

                        <hr className="mb-3 mt-0 mx-auto w-90 bg-secondary border color-theme" />

            <div className={'container-fluid'}>

            {items5.map((item, index)=><Card_2 title={item.title} menu={item.menu} key={index}/>)}
    </div>
    <div className='container-fluid mx-0 my-2 textleft'>
        <h3 className='text-pink text-center' > Funeral Benefits </h3>
        <ul className='text-left text-secondary'>
            <li>All members and nominated beneficiaries have the same cover</li>
            <li>Children under six years qualify for a maximum of R10 000</li>
            <li> Whole life cover</li>
            <li>No waiting period for accidental death on condition first is paid</li>
            <li>Only 6 months waiting period on death due to natural causes</li>
            <li>12 months waiting period for death by suicide</li>
            <li> No medical is required</li>
            <li> Olderst person determines the rates</li>
            <li>Provided us with all required documentation is submitted</li>
        </ul>
        <hr className="mb-3 mt-0 mx-auto w-90" />

        <div className='mx-2 border rounder'>
            <h3  className='text-pink text-center' >Isaiah Plan</h3>
            <div className={'container-fluid'}>

{isiah.map((item, index)=><Card_2 title={item.title} menu={item.menu} key={index}/>)}
</div>

        </div>

    </div>
    <div className='container-fluid mx-0 my-2 textleft'> 
    <h3 className='text-pink text-center' > Basic Funeral Services </h3>
   
    <div className='row mx-0 px-1 my-0'>
        <div className='col-sm-4 m-0 px-1 py-1'> 
            <div class="card w-100" >
                <div class="card-header">
                    <h4 className='text-pink'>Medo-Persia</h4>
                </div>
                <div className='card-body text-white bg-secondary'>
                    <p>
                        6 Members R286 <br/>
                        8 Members R338<br/>
                        10 Members R349
                        14 Members R 491
                    </p>
                </div>
            </div>
        </div>
        <div className='col-sm-4 m-0 px-1 py-1'> 
            <div class="card w-100" >
                <div class="card-header">
                    <h4 className='text-pink'>Anglo-America Plan</h4>
                </div>
                <div className='card-body text-white bg-secondary'>
                    <p>
                        6 Members R358 <br/>
                        8 Members R4200<br/>
                        10 Members R631
                        14 Members R689
                    </p>
                </div>
            </div>
        </div>
        <div className='col-sm-4 m-0 px-1 py-1 text-left'> 
            <div class="card w-100" >
                <div class="card-header">
                    <h4 className='text-pink'>Anglo-America Menu</h4>
                </div>
                <div className='card-body text-white bg-secondary'>
                    <ul>
                        <li>Pap/Rice</li>
                        <li>Oxtail</li>
                        <li>Salad</li>
                        <li>Chakalaka</li>
                        <li>Juice, ( Gemere, Magalies, Summer Land )</li>
                   
                    </ul>
                    
                </div>
            </div>
        </div>
      
        
    </div>


    </div>
        </div>
        <Footer />
    </>)

}
