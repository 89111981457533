import "./index.scss";
import CheckMark from "../../assets/img/checkMark.png";

const secListItems = [
  {
    main: "Registered under FSCA No: ",
    span: "50965 Juristic Representative",
  },
  {
    main: "Our Policies are administered by Havana life insurance",
    span: "",
  },
  {
    main: "Underwritten by RMA.",
    span: "",
  },
];

const thirdListItems = [
  {
    main: "Tombstone Royal",
  },
  {
    main: "Glass Tombstone",
  },
  {
    main: "CPE",
  },
];

function CheckListItem(props) {
  return (
    <div className="check-item container-fluid d-flex flex-row my-0 px-4 text-white">
      <b className="checkMark">
        <img src={CheckMark} />
      </b>
      <b className="check-item-text py-1 px-1">
        {props.main}
        <b className="addtext">{props.span}</b>
      </b>
    </div>
  );
}
function BlueContainer() {
  return (
    <div className="main-container my-4">
      <div className="inner-container py-3">
        <div className="first w-100 text-white text-center">
          <div className="icon">
            <i className="fas fa-bell m-1"></i>
          </div>
          <div className="first-content">
            “Do you need clarity on any plan of our services or plan, we are
            here for you.
            <br />
            Talk to us!!! “
          </div>
          <hr
            style={{
              //background: 'white',
              color: "white",
              borderColor: "white",
              height: "1px",
            }}
          />
        </div>
        <div className="container-fluid row m-0 p-0 ">
          <div className="  mx-0 my-3 col-sm-6">
            <div className="second mx-auto rounded w-70">
              <div className="icon my-2 w-100 text-white text-center">
                <i className="fa fa-certificate m-1"></i>
              </div>
              <div className="second-content container-fluid m-0 py-2 px-3 text-center text-white rounded shadow">
                <h3>GPS Funeral Home and Events is:</h3>
                <br />

                <div className="secList mx-auto text-left my-0">
                  {secListItems.map((item) => (
                    <CheckListItem main={item.main} span={item.span} />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="third mx-0 border-left rounded my-3 px-3 col-sm-6">
            <div className="icon w-100 text-white text-left my-2">
              <i className="fa fa-handshake text-white m-1"></i>
            </div>

            <div className="third-content text-left">
              <h3 className="text-white">Working together with:</h3>
              <div className="cont container mx-auto my-2 text-left">
                {thirdListItems.map((item, index) => (
                  <CheckListItem
                    main={item.main}
                    span={item.span}
                    key={`index ${index}`}
                  />
                ))}
              </div>
            </div>
            <div className="p-2 text-white">
              <b>
                NB: It is very important to check compliance of the funeral
                cover before you join, confirm that the company:
              </b>
            </div>

            <div className="nota-bene-list mb-1">
              <ul>
                <li className="list-item" key={"1"}>
                  Is registered with the FSP
                </li>
                <li className="list-item" key={"2"}>
                  Is underwritten by a reputable insurance company
                </li>
                <li className="list-item" key={"3"}>
                  Is a member of the Funeral Practitioners Association
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlueContainer;
