import React,{useEffect,useState} from 'react';
import './index.scss';
import Button from '../../components/ButtonPink';
import SubmitForm from '../../components/SubmitForm';
import MSGDialogue from '../../components/Dialogue';
import { APIurl,rootUrl } from '../../App';
import axios from 'axios'

export default function Video(){

const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [diaglogueID, setdiaglogueID] = useState("msg4");
  const [logging, setLogging] = useState(false);

  const  process = (event)=>{
    event.preventDefault();
    setLogingmsg("Uploading...... ");
    setColors(" text-white bg-secondary");
    try {
      document.getElementById(diaglogueID).style.display = "block"; 
    } catch (error) {
      console.log("element is not found");
    }
   
    setLogging(true);
   
  let caption = document.getElementById("caption").value;
  let category = document.getElementById("category").value;
 
  let photo = document.getElementById("photo").files.item(0);

  var ok  =1;

  let cmd = document.getElementById("cmd").value;

  if(ok==1){
    
      
    axios.post(APIurl,{ 
        cmd: "addphoto",
        caption:caption ,
        category:category,
        photo:photo   
},{
headers: {
'Content-Type': 'multipart/form-data'
}}
)
        .then((response) => {
        
          console.log(response.data);
          document.getElementById("register_form").reset();
          var res = response.data;
          if(!res["success"]){
              setLogingmsg(res["data"]);
              setColors(" text-white bg-danger");
          }
          else{
            setLogingmsg(res["data"]);
              setColors(" text-white bg-success");
              setTimeout(()=>{
               
                // eslint-disable-next-line no-restricted-globals
              location.href = rootUrl + "/manage" ;
             // location.ref = ;
            },1500);
            
            
              
          }
          setLogging(true);
          document.getElementById(diaglogueID).style.display = "block"; 
          // eslint-disable-next-line no-restricted-globals
          //location.href = 'http://localhost:81/softrans/API.php';
            // Handle data
        })
        .catch((error) => {
          console.log(error);
        })
  }
  else{
      alert("Please fix errors!")
  }
}

  
    return(<>
    
        {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   

    <div className='container-fluid m-0 p-1 bg-white'>

        <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
            <div className='h3'>
                <Button name="Add Photo" styles = "h3"/>
            </div>

        </div>
        <div className='container-fluid m-0 p-0'>
        <form className='form-group container border text-center py-3 px-2' method='post' id='register_form'  onSubmit={process}>
        <input className='form-control' name="cmd" id="cmd" type="text" defaultValue='addphoto' hidden/>
            <h4>
                Fill in the form below
            </h4>
            <hr  className='bg-secondary mx-4 my-2'/>

            <div className='row m-0'>
              
              <div className='form-group col-sm-4'>
                  <textarea className='form-control' type="text" id="caption" placeholder='Enter photo caption' required></textarea>
              </div>

              <div className='form-group col-sm-4'>
                  <select id="category"  className='form-control' required>
                      <option value="">-----Choose category----</option>
                      <option value="Coffins">Coffins</option>
                      <option value="Decorations">Decorations</option>
                      <option value="Foods">Foods </option>
                      <option value="Drinks">Drinks </option>
                      <option value="Funerals">Funerals </option>
                      <option value="Catering">Catering </option>
                      <option value="Grocery">Grocery</option>
                  </select>
              </div>

              <div className='form-group col-sm-4'>
                <span> <b>Choose Photo from Gallery</b></span>
                  <input className='form-control' type="file" id="photo" required/>
              </div>

            </div>
           
           
            <SubmitForm name="Submit Photo"/> 
        </form>
        </div>
       
        

    </div>

    </>)

}
    