import "./index.scss";
import ButtonSquare from "../../components/ButtonSquare";

function Contact() {
  return (
    <>
      <div className="container-fluid mx-0 my-3 py-0">
        <div className="container text-center to-start py-2">
          <h2 className="header-text">Contact Us</h2>
          <hr className="w-50 bg-dark mx-auto my-1 border border-dark to-start" />
        </div>

        <div className="container my-2 py-2 px-3 contact-container">
          <div className="contact-1">
            <h3 className="heading-span">Working Hours</h3>
            <hr className="bg-light w-50 text-left mx-0 p-0 my-0"></hr>
            <div className="my-2">
              <p className="text-secondary ">
                <b>Monday-Friday :</b> 09:30H to 17:30H <br />
                <b>Holidays :</b> Appointment only <br />
              </p>
            </div>
          </div>

          <div className="contact-1">
            <h3 className="heading-span">Contact Details</h3>
            <hr className="bg-light w-50 text-left mx-0 p-0 my-0"></hr>

            <div className="my-2">
              <p className="text-secondary ">
                <b>WhatsAppp :</b> 060 762 5627 <br />
                <b>Landline :</b> +27 (10) 634 2230 <br />
                <b>Consult :</b> +27 (13) 170 5658 <br />
                <b>Email : </b>
                <a className="mail-link" href="mailto:info@gpsfuneral.co.za">
                  <span className="value mail-link">info@gpsfuneral.co.za</span>
                </a>{" "}
                <br />
                <b>Saturday: </b>10:00H to 14:00H <br />
                <b>Sunday : </b>Closed <br />
              </p>
            </div>
          </div>

          <div className="contact-1">
            <div className="contact-title">
              <h3 className="heading-span">Location</h3>
              <hr className="bg-light w-50 text-left mx-0 p-0 my-0"></hr>
            </div>
            <div className="contact-content">
              <p className="text-secondary">
                {" "}
                Tweefontein ( F) Small industrial Place
                <br />
                Kwandebele
                <br />
                Mpumalanga
              </p>
            </div>
          </div>
          <div className="button-container d-flex flex-row justify-content-end">
            <ButtonSquare name="Directions " Icon="arrow-right" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
