import Button from '../../components/ButtonPink';
import Footer from "../Footer";
import Img1 from '../../assets/img/gp1.jpg'

import ImageGallery from "react-image-gallery";
import prices from '../../assets/img/PriceList.png'

const images = [
    {
        original:Img1,
        thumbnail:Img1
    },
]
function Cards(props){
    const count = props.menu.length/2;
const items = props.menu;
const firstHalf = items.slice(0, count);
const secondHalf = items.slice(count, count * 2);

    return (
        <>
      <div className="card  my-3 p-0 container-fluid card-30" >
                <div className="card-header bg-pink text-white text-center">
                  <b>{props.title}</b>
                </div>
                <div className=" card-body text-left ">
                    <div className={'text-start container-fluid'}> {items.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                </div>
        </div>
        </>
    )
}

function Card_2(props){
    const count = props.menu.length/2;
const items = props.menu;
const firstHalf = items.slice(0, count);
const secondHalf = items.slice(count, count * 2);

    return (
        <>
      <div className="card  my-3 p-0 container-fluid " >
                <div className="card-header bg-pink text-white text-center">
                  <b>{props.title}</b>
                </div>
                <div className=" card-body text-left go-flex">
                    <div className={'text-start container-fluid'}> {firstHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                      <div className={'text-start container-fluid'}> {secondHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                </div>
        </div>
        </>
    )
}

export default function GroceryPolicy() {

    const items = [

        {
            title: "Less than 1 year old",
            menu: [
                'Hearse','Body Coffin','Body Preparer','Grave Side','Flowers'
            ]
        },
          {
            title: "1 to 4 years old",
            menu: [
                'Hearse','Family Car','Body Coffin','Grave Side','Flowers'
            ]
        },
         {
            title: "5 years and older",
            menu: [
                'Services','Hearse','Family Car','Coffin','Grave side','Flowers'
            ]
        },


    ];

const items2 = [
        {title:'Timothy Plan',
        menu:[
          'Beef stew (4kg) x2 ',
            'Rice (10kg)',
            'Samp (10kg)',
            'Mayonnaise (3kg) x2',
            'Salt (1kg)',
            'Spice Packs',
            'Macaroni (2kg) x2',
            'Cooking Oil (5L)',
            'Beetroots (10kg)',
            'Pumpkin bags',
            'Cucumber box',
            'Lettuce box',
            'Green pepper',
            'Disposable cups',
            'Disposable spoons',
            'Disposable plates',
        ]
        },
        {title:'Ezekiel Plan',
        menu:[
            "12,5kg maize meals ",
          "  Beef stew X2 4kg ",
            "Packs of soups", 
            "7kg onions", 
            "7kg green pepper ",
         "   5L cooking oil ",
           " 10kg carrots", 
           " Beans x12",
          "  2x1kg chutney ",
           " 1kg tomatoes sauce ",
           " Paprika packs",
           " Cabbage 7s",
         "   Spices ",
           " Stocks cubes",
        ]
        },
       
        {title:'Proverbs Plan',
        menu:[
            "10kg rice",
           " 5L cooking oil",
            "12s beans",
          "  3kg mayonnaise",
           " 3kg tomatoes sauce",
           " Pack of soups",
           " Salt 1kg k",
           " Vegs",
           " Cabbage 7s",
            "Potatoes 7kg",
           " Pumkin 10kg",
           " Green pepper 5kg",
        ]
        },
       
       
        
        {title:'Jude Plan',
        menu:[
            "10kg rice",
            "2kg sugar",
          "  2x5kg beans",
           " 1kg salt",
           " Juices 5L",
           " 2x3kg mayonnaise",
           " Tomatoes sauce",
           " Spices packs",
           " Curry powder ",
           " Samp 10kg",
            " Beef meat 5kg",

           " Vegs",
           " Box of tomatoes ",
           " Box of green pepper ",
           " Bags of onions ",
           " Bags of carrots ",
           " Bags of pumpkin ",
            "Bags of Cabbage ",
           " Bags of potatoes ",
          "  DISPOSAL spoon ",
          "  DISPOSAL cups ",
           " DISPOSAL plate ",
           " Scrub pot",
           " Washing dishes ",
           " Steel wool ",
           " Refuse bags"
        ]
        },
        {
            title: 'River Plan',
            menu: [
                'Onions (7kg)',
                'Carrots (10kg)',
                'Pumpkin 10kg',
                'Green pepper',
                'Cabbage (7s)',
                'Beetroot (10kg)',
                'Maize meal (25kg)',
                'Beef Stew (3kg) x2',
                'Packs of soups',
                'Paprika',
                'Packs of spices',
                'Chutney',
                'Cooking Oil (5L)',
                'Salt',
                'Juice (4L)',
                'Disposable cups',
                'Disposable plates',
                'Stock cubes pack'
            ]
        }
        ];

    return (<>
        <div className='container-fluid mx-0 mb-4 p-1 bg-white text-center'>
            <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
                <div className='h3 animate__animated animate__fadeInDown'>
                    <Button name="Grocery Policy" styles="h4" />
                </div>
            </div>
            <div className={'container-fluid row p-0 m-0'}>
                <img src={prices} className = "col-sm-6 mx-auto my-2 p-0"  alt = "prices" />

            </div>
            
    <div className='container-fluid mx-0 my-3 p-0 '>
          <div className="card my-2 mx-1 " >
                <div className="card-header bg-secondary text-white text-center">
                <b>Our grocery Plans offerings</b>
                </div>

        </div>




       </div>




       <div className='container-fluid mx-0 my-2 p-0 d-flex flex-wrap justify-content-around manage-services'>

            </div>



          <div className={'container-fluid'}>

            {items2.map((item, index)=><Card_2 title={item.title} menu={item.menu} key={index}/>)}
    </div>

              <div className={'card-img-top img-fluid container-fluid cover m-0 p-0'}>
              
                {/* <img src={Img1} className={'container-fluid rounded card-50 height-vp-60 cover'}/> */}
               
            </div>
            <div className="card-body" >
            Burial Grocery Plan 
A Burial Grocery Plan helps you plan ahead, providing your family with peace of mind that you have provided for funeral expenses. <br/>
Cover your family today with Burial Grocery.
<ol className='text-left'>
    <li className='text-secondary'>Make sure you regularly speak openly, honestly, and constructively with your spouse or family rather than ignoring the matter.</li>
    <li  className='text-secondary'>Encourage them to express their feelings and worries about you being the main breadwinner</li>
</ol>


            </div>


        </div>
        <Footer />
    </>)

}
