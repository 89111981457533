import './index.scss'

import SubmitForm from '../../components/SubmitForm';

import React,{useEffect,useState} from 'react';

import MSGDialogue from '../../components/Dialogue';
import { APIurl,rootUrl,BaseAPIurl } from '../../App';
import axios from 'axios'


function Form() {


    const [loginmsg, setLogingmsg] = useState("");
    const [colors, setColors] = useState("");
    const [diaglogueID, setdiaglogueID] = useState("msg10");
    const [logging, setLogging] = useState(false);
    
    
    const  process = (event)=>{
        event.preventDefault();
        setLogingmsg("Sending Messaging...... ");
        setColors(" text-white bg-secondary");
        setLogging(true);
        try {
            document.getElementById(diaglogueID).style.display = "block"; 
          } catch (error) {
            console.log("element is not found");
          }
       
      let name = document.getElementById("name").value;
      let email = document.getElementById("email").value;
      let phone = document.getElementById("phone").value;
      let message = document.getElementById("message").value;
    
      var ok  =1;
    
      if(ok==1){

            axios.post(APIurl,{ 
            cmd: "sendmessage",
            name:name ,
            email:email,
            phone:phone,   
            message:message 
            }
    )
            .then((response) => {
            
              console.log(response.data);
              document.getElementById("register_form").reset();
              var res = response.data;
              if(!res["success"]){
                  setLogingmsg(res["data"]);
                  setColors(" text-white bg-danger");
              }
              else{
                setLogingmsg(res["data"]);
                  setColors(" text-white bg-success");
                  
              }
              setLogging(true);
              document.getElementById(diaglogueID).style.display = "block"; 
              // eslint-disable-next-line no-restricted-globals
              //location.href = 'http://localhost:81/softrans/API.php';
                // Handle data
            })
            .catch((error) => {
              console.log(error);
            })
      }
      else{
          alert("Please fix errors!")
      }
    }
    



    return (
        <>
        {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   

         <div className='container-fluid mx-0 my-3 py-0'>
        
        <div className='container text-center  to-start py-2'>
        <h2 className='header-text'>Get In Touch</h2>
         < hr className='w-50 bg-dark mx-auto my-1 border border-dark to-start' />
        </div>

        <form className='container my-2 py-2 px-3 form-group border form-container shadow rounded'  method='post' id='register_form'  onSubmit={process}>

        <div className='form-group my-2 py-1 text-secondary'>
            <h4 className=' text-center mt-0 mb-2'> Fill the form below</h4>
            <hr className='bg-light w-90 mx-auto' />

        <div className='form-group p-0 m-1'>
            <input className='form-control' type="text" id='name' placeholder='Enter full names*' required/>
        </div>

        <div className='form-group p-0 m-1' >
            <input className='form-control' text="email" id='email' placeholder='Email Address' required/>
        </div>
        
        <div className='form-group p-0 m-1' >
            <input className='form-control' text="text" id="phone"  placeholder='Cellphone Number' required/>
        </div>
    
        <div className='msg-text'>
            <b>Message</b>
        </div>

        <div className='form-group p-1'>
            <textarea className='form-control' text="text" id='message'  placeholder='Type your message here...' ></textarea>
        </div>

    </div>
      <SubmitForm name="Send Message" type='submit' Icon="angle-right"/>
 
</form>
        </div>
        </>
     
       
    );
}

export default Form;