import React from 'react';
import "./index.css";


export default function Menu(props) {
    return (
        <>

            <div className={"button-submit p-1 rounded " + props.style}>
                <div className="inner-layer rounded px-3 text-white hover">
                    <input type="submit" value={props.name}/> <i className={"fa fa-" + props.Icon + " mx-2 "} ></i>
                </div>

            </div>
        </>
    )

}