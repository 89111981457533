import Button from '../../components/ButtonPink';
import Footer from "../Footer";
import Img1 from '../../assets/img/catering1.jpg'
import Img2 from '../../assets/img/catering2.jpg'
import Img3 from '../../assets/img/catering3.jpg'
import Img4 from '../../assets/img/catering4.jpg'
import Img5 from '../../assets/img/catering5.jpg'



const str1 = 'Food is the heart and soul behind every event, make yours one to remember with exquisitely delectable, hearty and health meals that tantalise the taste buds and impress your guests';
const str2 ='We Provide Professional chefs Catering staff that care of all the funeral catering requirements';
const imageCards = [
    {
        image:Img1,
        body:''
    },
    {
        image:Img2,
        body:''
    },
    {
        image:Img3,
        body:''
    },
    {
        image:Img4,
        body:''
    },
    {
        image:Img5,
        body:''
    }

]
const equipments = [
    'Gas Stove',
    'Gas bottle',
    'Pots',
    'Waiters',
    'Tree Food (pot)',
    'Full services',
    'Meats' ,
    'starchs',
    'Veggies',
    'Rolls',
    'Transport excluded & Cutlery excluded'
];
const events = [
    'Weddings',
    'Funerals',
    'Parties',
    'corporate event'
]
const pars = [
    'We would like to be of assistance to cater for any of your future events, meetings, training meals, executive lunches, fare well & etc',
    'Focus on  providing superb services and quality meals. We offer gemere juice, quality meals, platters, cocktails, canape foods as well as plated breakfast, corporate lunches and dinners'
]
function Card_2(props){
    const count = props.menu.length/2;
const items = props.menu;
const firstHalf = items.slice(0, count);
const secondHalf = items.slice(count, count * 2);

    return (
        <>
      <div className="card  my-3 p-0 container-fluid " >
                <div className="card-header bg-pink text-white text-center">
                  <b>{props.title}</b>
                </div>
                <div className=" card-body text-left go-flex">
                    <div className={'text-start container-fluid'}> {firstHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                      <div className={'text-start container-fluid'}> {secondHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                </div>
        </div>
        </>
    )
}

function ImageCard(props){
   const item = props.item;

  return ( <div className={'card card-45 rounded my-3'}>
      <img src={item.image} className={'card-img-top height-vp-60 cover'}/>
      <div className={'card-body bg-secondary text-white'}>
          <p>
              {item.body}
          </p>
      </div>

   </div>)

}
function PlansCard(props){

    return (<>
     <div className='col-sm-4 p-1 m-0'>
                        <div className='card text-left'>
                            <div className='card-title bg-pink text-white'>
                                <h3 className='text-center'>{props.title}</h3>
                            </div>
                                <div className='card-body'>
                                    {props.list.map((item,index)=><span  key={index}><i className='fa fa-circle-check text-pink '></i> {item}<br/></span>)}
                                
                                <hr />
                                <h4>Premium</h4>
                                <ul className='text-secondary'>
                                    {props.pre.map((item,index)=>  <li key={index}>{item}</li>)}
                                </ul>
                            </div>
                           
                        </div>
                    </div>
    </>)

}
export default function CateringPolicy() {

    const items = [

        {
            title: "Less than 1 year old",
            menu: [
                'Hearse','Body Coffin','Body Preparer','Grave Side','Flowers'
            ]
        },
          {
            title: "1 to 4 years old",
            menu: [
                'Hearse','Family Car','Body Coffin','Grave Side','Flowers'
            ]
        },
         {
            title: "5 years and older",
            menu: [
                'Services','Hearse','Family Car','Coffin','Grave side','Flowers'
            ]
        },


    ];



        const plans1 = [{title:"Exodus plan",list:["Groceries with vegetables","Tents and chairs","Catering izandla","Equipment of catering", "Normal toilet"],
                        pre:["Individual R50", "Couples R80", "Family plan R100", "6 members R200", "8 MEMBERS R220","10 members R250", "14 member R330" ]},
                        {title:"Barley plan", list:["Catering team izandla","Equipment cater","Grocerys with vegetables"], pre:["Individual R46","Couples R57","Family plan R80","6 members R120","8 MEMBERS R140","10 members R150","14 member R200"]},
                        {title:"Flax plan",list:["Chef's","Assistance of chef's","Grocerys with vegetables","Tables, Tents, and chairs","toilets and cool rooms","urn water boiler"],pre:["Individual R100","Couples R150","Family plan R170","6 members R460","8 MEMBERS R550","10 members R600","14 member R900"]},
                        {title:"Blossom plan",list:["Chefs","Vegs and groceries","Chairs and tents","Normal toilet"],pre:["Individual R129","Couples R145","Family plan R 187", "6 members R300","8 member R400","10 members 430","14 member R600"]},
                        {title:"Babylon plan",list:["Catering team izandla","Equipment catering","Groceries with vegetables","Bus and inkuni"], pre:["Individual 91","Couples R100","Family plan R130","6 members R226","8 MEMBERS R278","10 members R298","14 member R431"]},
                        {title:"Egypt plan",list:["Private chef's","Waiters","Groceries and vegetables"," Equipment","Tents and chairs","toilets and cool rooms","Breakfast"], pre:["Individual R200","Couples R230","Family Plan R250","6 members R500","8 MEMBERS R560","10 members R600","14 member R950"]}
                        
                       
                        
                    ];

    return (<>
        <div className='container-fluid mx-0 mb-4 p-2 bg-white text-center'>
            <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
                <div className='h3 animate__animated animate__fadeInDown'>
                    <Button name="Catering Policy" styles="h4" />
                </div>
            </div>


    <div className=' card container-fluid my-3 p-0 bg-secondary '>
          <div className="card-body my-2 mx-1 " >
                <div className="card-header bg-secondary text-white text-center card-title">
                <b>Includes all equipments</b>
                </div>
              <div className={'card-text text-white text-left go-flex'}>
                      <ul>
                          {equipments.map((equipment, index)=><li key={index} >{equipment}</li>)}
                      </ul>
                        <ul>
                            <li> <b>50 seater with canibal stretch tent plus free 10 seater</b></li>
                                <li>
                                    <b>Catering service for all events:</b>
                                    <ol>
                                        {events.map((event, index)=><li key={index} >{event}</li>)}
                                    </ol>
                            </li>
                        </ul>

              </div>

             <p className={''}>
                Catering services also available for memorial services and menus are designed based on the family's needs
             </p>

<p className='text-white'>
In order to arrange a unique funeral services for each bereaved family our professional and well trained personnel see to every  details no matter how large or small

</p>

        </div>

       </div>

              <div className={'container-fluid m-0 p-0fluid go-flex'}>
                {
                    imageCards.map((imageCard, index)=><ImageCard key={index} item={imageCard}/>)
                }
            </div>




       <div className='container-fluid mx-0 my-2 p-0 d-flex flex-wrap justify-content-around manage-services'>

            </div>






            <div className={'container-fluid'}>
                <div className={'card rounded'}>
                    <img className={'card-img height-vp-60 cover'} src={'https://images.unsplash.com/photo-1555244162-803834f70033?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y2F0ZXJpbmd8ZW58MHx8MHx8&auto=format&fit=crop&w=2000&q=60'}/>
                    <div className={'container-fluid bg-op card-img-overlay text-white text-center d-flex flex-column justify-content-center py-3'}>
                        {
                            pars.map((par, index)=> <h4 className='p-0 m-0' key={index}>
                                {par}
                            </h4>)
                        }
                    </div>
                </div>
            </div>
            <div className='container-fluid px-2 py-2 text-white bg-secondary text-left rounded '>

            <b>How to deal with challenges of being a breadwinner in your home:  <br/></b> 
                Managing your personal finances and living your life according to your own terms as a woman breadwinner in South Africa can be challenging.
                Tents, toilet, executive
                Sit down arrangement _100 which includes, chef, food warmers, juice dispenser, ceramic plates, drinks glass, polystyrene plate for takeaway, 
                big pot, serving stations cutlery and crockery, chairs, table, waiters, gas stove,cylinder gas, free, cooking complimentary, breakfast, equipment, mobile fridge plus mobile toilets, groceries +more...

            </div>
            
            <div className='container-fluid mx-0 my-2 p-0'>
                <div className='row mx-0 my-1 p-0'>
                  {plans1.map( (item,index) => <PlansCard key={index} title={item.title} list={item.list} pre={item.pre} />) }
                </div>
            </div>




        </div>
        <Footer />
    </>)

}
