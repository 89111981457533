import React, { useEffect, useState } from 'react';
import './index.scss';
import Button from '../../components/ButtonPink';
import ButtonSquare from '../../components/ButtonSquare';
import image from '../../assets/img/3d-logo-dark-bg.jpg';
import SubmitForm from '../../components/SubmitForm';
import FooterHelp from '../FooterHelp';
import { BaseAPIurl } from '../../App';
import about1 from '../../assets/img/About1.jpg';
import about2 from '../../assets/img/About2.jpg';
import about3 from '../../assets/img/10.jpg';
import Footer from "../Footer";






export default function Video() {

    const photos = [{ id: 1, caption: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore", img: image },
    { id: 2, caption: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore", img: image }]


    return (<>
        <div className='container-fluid mx-0 mb-4 p-1 bg-white text-center'>

            <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
                <div className='h3 animate__animated animate__fadeInDown'>
                    <Button name="About Us" styles=" h3" />
                </div>
            </div>

            <div className='container-fluid mx-0 my-2 p-0 go-flex'>


                <div className="card my-2 mx-2 rounded shadow card-width-33" >
                    <a href={about1}><img className="card-img-top" src={about1} alt="Card image cap" /></a>
                    <div className="card-body p-1 bg-secondary text-white text-left">
                        <ul>
                            <li>We provide you with excellent services
                                that suit your needs, it can be funerals
                                catering, etc... Home cooked food with
                                dedicated understanding staff, catering
                                equipment, executive mobile toilet
                                grocery and more </li>
                            <li>we cater any kind of event, depending
                                on what you need .</li>
                        </ul>
                    </div>
                </div>

                <div className="card my-2 mx-2 rounded shadow card-width-33" >
                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                    <a href={about2}><img className="card-img-top" src={about2} alt="Card image cap"    /></a>

                    <div className="card-body p-1 bg-secondary text-white text-left">
                        <ul>
                            <li>We care about our customers and
                                committed to provide a true value at all
                                times. We provide quality funeral services
                                facilities with respect care and understa-
                                nding for families we serve.</li>
                            <li>We are company that offers  better
                                service to assist our clients or our
                                communities in the time of need, by
                                offering affordable premium to buy loved
                                ones with dignity any kind of event .</li>
                        </ul>
                    </div>
                </div>
            </div>

            <hr className="my-2 mx-auto w-90 bg-secondary border" />
            <img src={about3} alt="Card image cap" className="w-90 mx-auto rounded my-2 img-60"  />
            <ol className='text-left px-4 py-2 mx-2 text-secondary'>
                <li>Foreign nationals with valid  are covered</li>
                <li>No waiting period for <span className='text-pink'>accidental Death</span></li>
                <li>A maximum of <span className='text-pink'>10 members</span> are allowed to
                    join the policy with  an option to add extra
                    members</li>

                <li>Claims to be paid within <span className='text-pink'>48 hours ( 2 Days )</span></li>
                <li>No waiting period from other funeral
                    palours</li>
                <li> No trial on transfer policies </li>
                <li>National wide services with flexible claim
                    process</li>
                <li>Tailored  rates <span className='text-pink'>stokvel - Social clubs</span></li>
                <li>Flexible grocery list- we consider your needs</li>
                <li>Cash Funeral are tailor made to suit your
                    budget</li>
                <li> <span className='text-pink'>Waiting period</span> 6 months</li>
                <li>You can cover up to <span className='text-pink'>10 formal members</span></li>
                <li>Every policy has <span className='text-pink'>joining fee</span>, joining fee is
                    once off </li>

            </ol>

        </div>


        <Footer />
    </>)

}
