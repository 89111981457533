import React from "react";
import './index.scss' 



export default function Footer(){

    return(<>
        <div className="container-fluid mx-0 my-0 bg-pink text-white">
           <div className="text-center" ><h4>For Help Contact:</h4></div> 
            <div className="d-flex flex-row justify-content-between footer-help">
                <div  className="text-white"><i className="fab fa-whatsapp mx-2">  </i><b className="">+27 60 762 5627</b></div>
                <div className="text-white"><i className="fa fa-phone mx-2"></i><b className="">+27 10 634 2230</b> </div>
            </div>
            <div className='text-white text-center'> <h4> Developed By <a href='https://www.weball.co.za' className=''>Weball Technologies</a></h4></div>

        </div>
    </>);

}