import React from "react";
import { Outlet, Link, Navigate } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from '../Header';
import Home from "../Home";
import NoPage from "../NoPage";
import AddVideo from "../AddVideo";
import AddPhoto from "../AddPhoto";
import Management from "../Management";
import Videos from '../Videos';
import Coffins from '../Gallery/coffins';
import Decorations from '../Gallery/decorations';
import Foods from '../Gallery/food';
import Drinks from '../Gallery/drinks';
import Funerals from '../Gallery/funeral';
import Catering from '../Gallery/catering';
import Grocery from '../Gallery/grocery';
import Registration from '../Registration';
import Payment from '../Payment';
import PhotoDelete from '../PhotoDelete';
import VideoDelete from '../VideoDelete';
import SideNav from "../SideNav";
import AboutUs from '../AboutUS';
import FashionPage from "../FashionPage";
import ImagePage from "../ImagePage";
import FuneralServices from "../Services/FuneralServices";
import CateringServices from "../Services/CateringServices";
import AnykindServices from "../Services/AnykindServices";
import PolicyConditions from '../Services/PolicyConditions'
import FuneralPolicy from "../BrochurePlans/funeral";
import GroceryPolicy from "../BrochurePlans/grocery";
import CateringPolicy from "../BrochurePlans/catering";
import TombstonePolicy from "../BrochurePlans/tombstone";
import CowPolicy from "../BrochurePlans/cow";
import MeatPolicy from "../BrochurePlans/meat";
import AfterTearsPolicy from "../BrochurePlans/afterTear";
import AfterCarePolicy from "../BrochurePlans/afterCare";


export default function Layout() {

    return (
        <><BrowserRouter>
            <Header />
            <SideNav />
            <Routes>
                <Route index element={<Home />} />
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/addphoto" element={<AddPhoto />} />
                <Route path="/addvideo" element={<AddVideo />} />
                <Route path="/manage" element={<Management />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/videos" element={<Videos />} />
                <Route path="/coffins" element={<Coffins name="Coffins" />} />
                <Route path="/decorations" element={<Decorations name="Decorations" />} />
                <Route path="/foods" element={<Foods name="Foods" />} />
                <Route path="/drinks" element={<Drinks name="Drinks" />} />
                <Route path="/funerals" element={<Funerals name="Funerals" />} />
                <Route path="/catering" element={<Catering name="Catering" />} />
                <Route path="/grocery" element={<Grocery name="Grocery" />} />
                <Route path="/register" element={<Registration />} />
                <Route path="/funeralservices" element={<FuneralServices />} />
                <Route path="/cateringservices" element={<CateringServices />} />
                <Route path="/anykindservices" element={<AnykindServices />} />
                <Route path="/policyconditions" element={<PolicyConditions />} />
                <Route path="home/register" element={<Registration />} />
                <Route path="/payment" element={<Payment />} />
                <Route path="/photodelete" element={<PhotoDelete />} />
                <Route path="/videodelete" element={<VideoDelete />} />
                <Route path="/fashion" element={<FashionPage />} />
                <Route path="/image/:src" element={<ImagePage />} />
                <Route path={'/funeralPolicy'} element={<FuneralPolicy/>}/>
                <Route path={'/groceryPolicy'} element={<GroceryPolicy/>}/>
                <Route path={'/cateringPolicy'} element={<CateringPolicy/>}/>
                <Route path={'/tombstonePolicy'} element={<TombstonePolicy/>}/>
                <Route path={'/cowPolicy'} element={<CowPolicy/>}/>
                <Route path={'/meatPolicy'} element={<MeatPolicy/>}/>
                <Route path={'/afterTearsPolicy'} element={<AfterTearsPolicy/>}/>
                <Route path={'/afterCarePolicy'} element={<AfterCarePolicy/>}/>

                {/* <Route path="/receipts/burial/deleteburial" element={<BurialDelete />}/> */}
                <Route path="*" element={<NoPage />} />
            </Routes>

        </BrowserRouter><Outlet /></>

    );


}