import React from 'react';
import './index.scss';

export default function MissionSliderComponent(props) {
    return (

        <div className="container-fluid m-0 p-0 mission-card" style={{display:props.display}}>
        <div className="card position-relative top-0 my-3 overflow-hidden shadow"  >
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <img className="card-img-top animation-zooming-infinite d-lg-none " src={props.bg} alt="Card image cap"/>
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <div className='card-img-top p-hide'>
            <img className="" src={props.bg} alt={'Card image cap'}/>
            </div>
            <div className='card-img-overlay d-flex justify-content-center align-items-start'>
            <div className=' title btn shadow text-white px-3 py-2 text-center rounded animate__animated animate__fadeInDown mt-4 '><h4>{props.title}</h4></div>
            </div>
                <div className="card-body bg-secondary text-white ">
                <p className="card-text animate__animated animate__fadeInUp">{props.description}</p>
            </div> 
            </div> 
            <div className='controls d-flex flex-row'>
                {
                    props.controls.map((style,index) => <span key={index} onClick = {()=>{
                        props.onClick(index);
                    }}  className={ "mx-2 " + style}></span>)
                }
            </div>
        </div>
       

    )
}


