import React,{useEffect,useState} from 'react';
import './index.scss';
import Button from '../../components/ButtonPink';
import ButtonSquare from '../../components/ButtonSquare';
import { APIurl,rootUrl,BaseAPIurl } from '../../App';
import axios from 'axios';
import MSGDialogue from '../../components/Dialogue';
import ImageGallery from 'react-image-gallery';


function Cards(props){

    return (
        <>
      <div className="card my-2 card-30 animate__animated animate__fadeInUp" >
                <div className="card-header bg-pink text-white text-center">
                  <b>{props.title}</b>
                </div>
                <div className="card-body">
                   {props.menu.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink'></i> {item} <br/></span>)}
                </div>
        </div>
        </>
    )
}



export default function Decorations(props){

    const [photos,setPhotos] = useState([]);
    const [limit,setLimit] = useState(4);
    const [isEmpty,setEmpty] = useState("Loading Images");
    const [loginmsg, setLogingmsg] = useState("");
    const [colors, setColors] = useState("");
    const [logging, setLogging] = useState(false);
    const [diaglogueID, setdiaglogueID] = useState("msg16");

    useEffect(()=>{

    //Get all photos
    setLogingmsg("Getting Photos " + props.name +  " ...... ");
    setColors(" text-white bg-secondary");
    try {
      document.getElementById(diaglogueID).style.display = "block";
    } catch (error) {
      console.log("element is not found");
    }
    setPhotos([]);
    axios.post(APIurl,{
        cmd: "getphotobyid",
        id:props.name

        } )
        .then((response) => {

        var res = response.data;



        if(!res["success"]){
          console.log("check this ",res);
          setLogingmsg(res["data"]);
          setColors("Could not send request");
          setEmpty("No photo found");
        }
        else{

            if(res["data"].length ==0){

                setEmpty("No photo found");
              }
              else{
                setEmpty("");
              }


          const images = [];

          res["data"].forEach(element => {

            let el = { original: BaseAPIurl + "/Gallery/" + element.img,
                       thumbnail: BaseAPIurl + "/Gallery/" + element.img};
            images.push(el);

          });
          setPhotos(images);
          document.getElementById(diaglogueID).style.display = "none";
        }

        })
        .catch((error) => {

        console.log(error);

        });
    },[props]);

    const decoration = [

        {
            title: "Decorations",
            menu: ['Dripping with setup', 'Pyramid tent / Freme tents', 'Padded gold rim chairs', 'Turfs, green mats Roles', 'White gloss Floring tor tents','Red carpets', 'Stage decor','couches'

            ]
        },
          {
            title: "Additional Extras",
            menu:['Live Stream','TV', 'Screens','Grave Closure','Sound at home','Sound at grave side','Mobile toilets','Portable','Hand wash','Basic pyramid tents', 'Freme Tent', 'Marquees']
        },

    ];


    return(<>

{logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}

    <div className='container-fluid m-0 p-1 bg-white'>

        <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
            <div className='h3'>
                <Button name= {props.name + " Gallery"} styles = "h3"/>
            </div>
        </div>



    <div className='container-fluid mx-0 my-3 p-0 go-flex w-100'>
           {
               decoration.map((item, index)=><Cards key={index} title={item.title} menu ={item.menu}/>)
           }
       </div>

        <div className='container-fluid mx-0 my-3 p-0 text-center'>
            <h4>Photos</h4>
            <h3 className='text-danger'>{isEmpty}</h3>
        </div>





        <ImageGallery autoPlay= {true} items={photos} />

        <ButtonSquare name="Join Our Funeral Services" Icon="plus" />
    </div>

    </>)

}
