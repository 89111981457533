import './index.scss'
import Component from './component';
import graph from '../../assets/img/graph.svg';
import whowe from '../../assets/img/certificate.jpg';


function CompanySloga() {
    return (
        <div className="container-fluid m-0 py-4 px-4 position-relative top-0 left-0">
            <img src={graph} className="position-absolute graph-slogan d-none"/>
            <Component />

            <div className=' text-black px-2 py-1 m-0 card rounded mt-4 who-we-are' >
            {/* <img class="card-img" src={whowe} alt="Card image"/> */}
            <div class="card-body">
            <h3 className="text-pink" > Who we are</h3>
            <img src={whowe} height={'auto'} className='img w-100 rounded mx-auto' alt='certificate' />
            <hr />
                {/* <p>
                    Company  name <b className='text-primary'> Goodman's products and services pty Ltd</b> <br/>
                    Slogan:  <i className='text-italic text-secondary'>"Be courageous and strong trust in jehovah (God)".</i>  <br/>
                    CEO <b className='text-primary'>Mr Williams</b><br/>
                    Founded- <b className='text-primary'> 2021 </b> <br/>
                    Industry <b className='text-danger'>: Products, services,all kinds events</b><br/>
                    We do food testing before we do the services its can be weddings, braai, malobola, cocktail dessert and more<br/>
                    Province: <b className='text-primary'>Mpumalanga kwandebele</b><br/>
                    Address Tweefontein section F next to the police station R573 zimdala house No 13 New stand office place
                    Main road -<br/>
                    We offer funeral covers, funeral services, life cover insurance and for<b className='text-danger'> HIV positive</b>  status etc..<br/>
                    We do all kinds of events by booking<br/>
                    Lifestyle protection for  <b className='text-danger'> HIV +lives </b> benefits life cover up to <b className='text-primary'>20 millions</b> <br/>
                    Lumpsum disability up to <b className='text-danger'>R50000</b><br/>
                    Get a free  medical medical aids review<br/>
                    We do funeral schemes, critical iiness up <b className='text-danger'>  to 1 million</b> <br/>
                    Associations members scheme<br/>
                    We working with so many companies<br/>
                    Our policies are underwritten by <b className='text-primary'>RMA, Safrican insurance company</b> administrator by five star insurance company <b className='text-primary'>  FSP NO 50965 </b><br/>
                    We are fully registered services under <b className='text-danger'>FSP 50965</b> <br/>
                    GPS Funeral Home and Events <b className='text-primary'>trading as a JR</b> <br/>
                    #Kubayini ubhadalela umasincwabisane zoke inyanga? <br/>
                    Isizathu-wenzelabona Mhlana kuhlongakale umuntu ekhaya kungabi mthwalo ukwazi ukubulunga kuhle ungatshwenyeki ngalitho <br/>
                    Ngapha kwa GPS Funeral Home and Events ubhadalela ama services womcwabo bona ungaze uhlupheke ngelanga lomngcwabo. Umtjhumayeli 9:5 Uyatjho bona sizofa yingakho sibhadela I society
                </p> */}
            </div>
                
                
            </div>
          
            
        </div>
    )
}

export default CompanySloga