import React,{useEffect,useState} from 'react';
import './index.scss';
import Button from '../../components/ButtonPink';
import ButtonSquare from '../../components/ButtonSquare';
import { APIurl,rootUrl,BaseAPIurl } from '../../App';
import axios from 'axios';
import MSGDialogue from '../../components/Dialogue';
import ImageGallery from 'react-image-gallery';


function Cards(props){

    return (
        <>
      <div className="card my-2 card-30 animate__animated animate__fadeInUp animate__slow" >
                <div className="card-header bg-coffin-card text-pink">
                  <b>{props.title}</b> 
                </div>
                <div className="card-body p-0 m-0">
                    <ul>
                        {props.menu.map((item,index)=><li key={index} > {item} </li>)}
                    </ul>
                </div>
        </div>
        </>
    )
}

const list = [
    {menu:['Redwood casket ', 'Kiaat Casket', 'Maluti Casket']},
    {menu:['Maluti Quarterview', 'Teak ', 'Kiaat', 'Redwood' ]},
    {menu:['Redwood halfview', 'Standard casket ', 'Teak halftview ', 'casket', 'Maluti halfview ' ]},
    {menu: ['Dark stain porthole', 'Kiaat porthole', 'Fantasy', 'Walnut halfview', ' Windsor', 'Pine halftview ']}
]

function List(props){
    return    <div className=" my-1 p-0 animate__animated animate__fadeInDown" >
         <div className="card-body p-0 mx-1">
                    <ul>
                        {props.menu.map((item,index)=><li key={index} > {item} </li>)}
                    </ul>
                </div>

    </div>
}




export default function Coffins(props){

    const [photos,setPhotos] = useState([]);
    const [limit,setLimit] = useState(4);
    const [isEmpty,setEmpty] = useState("Loading Images");
    const [loginmsg, setLogingmsg] = useState("");
    const [colors, setColors] = useState("");
    const [logging, setLogging] = useState(false);
    const [diaglogueID, setdiaglogueID] = useState("msg16");
    
    useEffect(()=>{

    //Get all photos
    setLogingmsg("Getting Photos " + props.name +  " ...... ");
    setColors(" text-white bg-secondary");
    try {
      document.getElementById(diaglogueID).style.display = "block"; 
    } catch (error) {
      console.log("element is not found");
    }
    setPhotos([]);
    axios.post(APIurl,{ 
        cmd: "getphotobyid",
        id:props.name
        
        } )
        .then((response) => {

        var res = response.data;
        
       

        if(!res["success"]){   
          console.log("check this ",res);
          setLogingmsg(res["data"]);
          setColors("Could not send request");
          setEmpty("No photo found");
        }
        else{
           
            if(res["data"].length ==0){
                
                setEmpty("No photo found");
              }
              else{
                setEmpty("");
              }

         
          const images = [];

          res["data"].forEach(element => {

            let el = { original: BaseAPIurl + "/Gallery/" + element.img, 
                       thumbnail: BaseAPIurl + "/Gallery/" + element.img};
            images.push(el);

          });
          setPhotos(images);
          document.getElementById(diaglogueID).style.display = "none"; 
        }
       
        })
        .catch((error) => {
  
        console.log(error);
        
        });
    },[props]);

    const classicCasckets = ['Classic ostrich','Alligator','Crocodile']

                    
    const dome = [{title:"Cathedral Domes", menu:['Magnificent', 'Fantasy Dome']},
        {title:"Standard Domes", menu:['Light Stain Dome','Dark Stain','Polished pine']},
        {title:"Classic Dome", menu:['Presidential Dome', 'Black and White', 'Dube', 'Woven', 'Alligator', 'Crocodile', 'Nguni Brown', 'Nguni Black']}
    ]

    return(<>

{logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   

    <div className='container-fluid m-0 p-2 bg-white '>

        <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
            <div className='h3'>
                <Button name= {props.name + " Gallery"} styles = "h3"/>
            </div>
        </div>


            <h3> 1. Caskets Kingston</h3>
            <div className='container-fluid px-0 mx-0 my-3 d-flex justify-content-around card foods-photos flex-row'>

                {list.map((item,index)=><List key={index}  menu={item.menu} />)}

            </div>


        <div className='container-fluid mx-0 my-3 px-0'>
            <h3> 2. Classic Casket</h3>
            <div className='container-fluid px-0 mx-0 my-3 d-flex justify-content-start animate__animated animate__fadeInRight'>
                <ul>
                    {classicCasckets.map((item,index)=><li className={'text-pink'} key={index}> <b>{item}</b></li>)
                    }
                </ul>

            </div>
        </div>

            <div className='container-fluid mx-0 my-3 px-0'>
            <h3> 3. Dome</h3>
            <div className='container-fluid px-0 mx-0 my-3 d-flex justify-content-between foods-photos'>
            {dome.map((slide,index) => <Cards key={index} title={slide.title} menu={slide.menu} />)}
            </div>
        </div>
       
        <div className='container-fluid mx-0 my-3 p-0 text-center'>
            <h4>Photos</h4>
            <h3 className='text-danger'>{isEmpty}</h3>
            
            
        </div>
        <ImageGallery autoPlay= {true} items={photos} />
        <ButtonSquare name="Join Our Funeral Services" Icon="plus" />
    </div>

    </>)

}
    