import './App.css';
import './common/styles/index.css'; ///in every project 

import React from 'react';

import Layout from './containers/Layout';

// export const APIurl = "https://gpsfuneralhome.co.za/API.php";
// export const BaseAPIurl = "https://gpsfuneralhome.co.za/";

// export const APIurl = "http://localhost:81/gps_api/API.php";
// export const BaseAPIurl = "http://localhost:81/gps_api/";


export const APIurl = "https://gpsfuneralhome.co.za/API.php"; 
export const BaseAPIurl = "https://gpsfuneralhome.co.za/"; 


//eslint-disable-next-line no-restricted-globals 
var BaseURL = location.href;
BaseURL = BaseURL.split("/");
BaseURL = BaseURL[0] + BaseURL[1] + "//" + BaseURL[2];
export const rootUrl = BaseURL;


function App() {


  return (
    <>
      <Layout />
    </>

  );
}

export default App;
