import "./index.scss";
import React, { useEffect } from "react";
import ReviewComponet from "./Component";
import ImageGallery from "react-image-gallery";
import image2 from "../../assets/img/userReview1.jpg";
import image1 from "../../assets/img/userReview2.jpg";
import image3 from "../../assets/img/userReview3.jpg";
import rev1 from "../../assets/img/reviews/1704744300178.jpg";
import rev2 from "../../assets/img/reviews/1704744303079.jpg";
import rev3 from "../../assets/img/reviews/1704744306745.jpg";
import rev4 from "../../assets/img/reviews/reviews1.jpg";
import rev5 from "../../assets/img/reviews/reviews2.jpg";
import rev6 from "../../assets/img/reviews/rev3.jpg";
import rev7 from "../../assets/img/reviews/rev4.jpg";


const reviews = [
  {
    name: "Fikile Masango ",
    text: "I truly appreciate you and the services that you offer comes with your teams, caterings providing an excellent thanks a lot what you did.",
    image: image3,
  },
  {
    name: "Kagiso",
    text: "Catering food was absolutely beautiful people they enjoy your food & services we are so happy about your greater services",
    rating: 5,
    image: image1,
  },
  {
    name: "Joice Masango",
    text: "Dear GPS Funeral Thank you for the beautiful services the delicious food & team of catering professional things of your team you really make us happy & enjoy everything & our guests plus family we appreciate all your services",
    rating: 4,
    image: image2,
  },
 
];
const images = [
    {
        original:rev1,
        thumbnail:rev1
    },
    {
        original:rev2,
        thumbnail:rev2
    },
 {
        original:rev3,
        thumbnail:rev3
    },
    {
      original:rev4,
      thumbnail:rev4
  },
  {
    original:rev5,
    thumbnail:rev5
},
{
  original:rev6,
  thumbnail:rev6
},
{
  original:rev7,
  thumbnail:rev7
},

]
function UserReviews() {
  // var count = 0;
  // const MisionSliding = (index) => {
  //   count = count + index;

  //   let el = document.getElementsByClassName("review-container");

  //   for (let i = 0; i < el.length; i++) {
  //     el[i].style.display = "none";
  //   }
  //   if (count >= el.length) {
  //     count = 0;

  //     el[count].style.display = "block";
  //   } else if (count < 0) {
  //     count = el.length - 1;
  //     el[count].style.display = "block";
  //   } else {
  //     el[count].style.display = "block";
  //   }
  // };

  // useEffect(() => {
  //   MisionSliding(0); //show the 0 slider when start
  //   const intervalslide = setInterval(() => {
  //     MisionSliding(1);
  //   }, 20000); //continue animating
  //   return () => clearInterval(intervalslide);
  // }, []);

  return (
    <>
      <hr />
      <div className="container-fluid my-4 mx-0 px-1 py-1">
        <div className="mt-4 text-center container-fluid position-relative z-index-1">
          <div className="rectangle-block"></div>
          <h2>Client Reviews</h2>
        </div>
        
        <div className={'card cover rounded  my-2'}>
                <ImageGallery
                    items={images} autoPlay={true} showNav={false}
                    showThumbnails={true} fullscreen={true}
                    showFullscreenButton={true} showPlayButton={true}
                    showBullets={false} showIndex={true}
                    slideDuration={1000} slideInterval={5000}
                />
            </div>

        <div className="mx-auto container-fluid flex-container px-4 overflow-hidden justify-content-center d-flex">
          {reviews.map((item, index) => (
            <ReviewComponet
              key={index}
              name={item.name}
              text={item.text}
              imageUrl={item.image}
            />
          ))}
        </div>

        {/* <div className="controls-cover container-fluid  position-relative z-index-1">
          <div className="reviews-controls mx-auto bg-white my-2 py-2 px-4 text-center text-white border shadow ">
            <span
              className="control-but px-4 mx-1 border h2 rounded"
              onClick={() => {
                MisionSliding(-1);
              }}
            >
              <i className="fa fa-angle-left "></i>
            </span>
            <span
              className="control-but  px-4 mx-1 border h2 rounded"
              onClick={() => {
                MisionSliding(1);
              }}
            >
              <i className="fa fa-angle-right "></i>
            </span>
          </div>
        </div> */}
      </div>
      <hr />
    </>
  );
}

export default UserReviews;
