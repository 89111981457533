import './index.scss'
import { Outlet, Link, Navigate } from "react-router-dom";
import { useState } from 'react'


function SideNavMenuItem(props) {

    const [showSubmenu, setShowSubmenu] = useState(false)

    function handleMenu() {

       setShowSubmenu(!showSubmenu);

    }

    return (
        <div className="side-nav-menu-item animate__animated m-0 animate__fadeInDown border-bottom border-secondary" style={{animationDelay:props.item.delay + "s"}}>
            <div className="title-part text-white">
                <div className='d-flex justify-content-between position-relative top-0 flex-row px-2 py-1'>
                    <span className='d-flex left-0 mx-3'>{/*<i className= {"mt-1 d-none" + props.item.icon}></i>*/} <Link  className="menu-link text-white mx-2" to={props.item.link} onClick={ props.item.closeBar}  > {props.item.name}</Link></span>  
                    
                     {
                    //show dropdown Icon if item has submenu
                    props.item.hasSubmenu === true ?
                        <i className="fa fa-angle-down px-1 mt-1" onClick={handleMenu}></i> : ""} </div>
            </div>

            {showSubmenu ? <div className="submenu-part">

                <ul>

                    {props.item.submenu.map((submenu, index) => <li onClick={props.onClick} className=' p-1 m-0 text-white' key={index}> <Link className='text-white' to={submenu.href}><span>{submenu.name}</span></Link></li>)}

                </ul>

            </div> : null

            }
        </div>

    )

}

export default SideNavMenuItem