import React,{useEffect,useState} from 'react';
import './index.scss';
import Button from '../../components/ButtonPink';
import ButtonSquare from '../../components/ButtonSquare';
import image from '../../assets/img/3d-logo-dark-bg.jpg';
import SubmitForm from '../../components/SubmitForm';
import FooterHelp from '../FooterHelp';







export default function Video(){

    const photos = [{id:1,caption:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore",img:image},
    {id:2,caption:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore",img:image}]
  
    
    return(<>
    <div className='container-fluid mx-0 mb-4 p-1 bg-white'>

        <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
            <div className='h3 animate__animated animate__fadeInDown'>
                <Button name="Payment" styles = "h4"/>
            </div>
        </div>
        <div className='container-fluid mx-0 my-2 px-2'>
           

            <div className='text-secondary mb-3 animate__animated animate__fadeIn animate__slower'>
            Use the following banking details 
                to make payment then send proof 
                of payment 
            </div>
            <ButtonSquare name="Send proof of payment" style=" animate__animated animate__fadeInUp " href="https://wa.me/+2760765627"/>
        </div>
        <div className='container bg-secondary p-3 text-white animate__animated  animate__zoomIn'>
        <p>Account holder: <b>GPS Funerals</b> <br/>
        Bank name: <b>Tyme Bank</b> <br/>
        Account Number: <b>5300 1107 352</b> <br/>
        Ref: <b>Your phone number</b> <br/>
        Branch Code : <b>678910</b> <br/>
        Account Tyepe: <b>Current Account</b> <br/>
        </p>
        </div>    
        <div className='text-center'><h3>OR</h3></div>  


        <div className='container bg-secondary p-3 text-white animate__animated  animate__zoomIn mb-4'>
            <p> Account holder: <b>GPS Funerals</b> <br/>
                Bank name: <b>Nedbank</b> <br/>
                Account Number: <b>125 052 2129</b> <br/>
                Ref: <b>Your phone number</b> <br/>
                Branch Code : <b>198765</b> <br/>
                Account Tyepe: <b>Current Account</b> <br/>
            </p>
        </div>       
      
    </div>

    <div className=' animate__animated animate__fadeInUp  animate__slower container-fluid m-0 p-0 position-fixed bottom-0 left-0 z-index-1'>
        <FooterHelp />

    </div>


    </>)

}
    