

import React,{useEffect,useState} from 'react';

import './index.scss';
import MSGDialogue from '../../components/Dialogue';
import axios from 'axios'
import {APIurl} from '../../App';




export default function UserDelete(props){

  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [logging, setLogging] = useState(false);
  const [diaglogueID, setdiaglogueID] = useState("msg6");



    useEffect(() => {
          //eslint-disable-next-line no-restricted-globals
        if (confirm("Are you sure, you want to delete this photo?")) {
          //eslint-disable-next-line no-restricted-globals
          var id = location.href;
          id = id.split("id=");
          id  = id[1];
          axios.post(APIurl,{ 
            cmd: "photodelete",
            id: id
            
            } )
            .then((response) => {
    
            var res = response.data;
            console.log(res);
            if(!res["success"]){
                setLogingmsg(res["data"]);
                setLogging(true);
                setColors(" text-white bg-danger");
            }
            else{
                console.log(res["data"], " size is ", res["data"].length);
                setLogging(true);
               
                setColors(" text-white bg-success");
                setLogingmsg(res["data"]);

     
            }
           
            })
            .catch((error) => {
            console.log(error);
            })
          }
        
        setTimeout(()=>{
             //eslint-disable-next-line no-restricted-globals
             var BaseURL = location.href;
                BaseURL = BaseURL.split("/");
                BaseURL = BaseURL[0];
                  //eslint-disable-next-line no-restricted-globals
                location.href = BaseURL + "/manage";
            
        },
            2000
            )
      }, []);


        return(
            <>
          {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   
            </>
        )
        ;
    

  

}