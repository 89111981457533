import React from 'react';
import "./index.css";
import { Link} from "react-router-dom";


export default function Menu(props) {

   
    return (
        <>

            <div className={"button-square p-1 rounded " + props.style} onClick={props.onClick}>
                <div className="inner-layer rounded px-3 text-white hover">
                    <Link to={props.href}> <b>{props.name}</b> <i className={"fa fa-" + props.Icon + " mx-2 "} ></i></Link>
                </div>

            </div>
        </>
    )

}