import React, { useEffect, useState } from 'react';
import './index.scss';
import Button from '../../../components/ButtonPink';
import image from '../../../assets/img/3d-logo-dark-bg.jpg';
import about3 from '../../../assets/img/catering services 1.jpg';
import Footer from "../../Footer";






export default function CateringServices() {

    const photos = [{ id: 1, caption: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore", img: image },
    { id: 2, caption: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore", img: image }]


    return (<>
        <div className='container-fluid mx-0 mb-4 p-1 bg-white text-center'>

            <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
                <div className='h3 animate__animated animate__fadeInDown'>
                    <Button name="Catering Services" styles="h3" />
                </div>
            </div>

            <hr className="my-2 mx-auto w-90 bg-secondary border" />
            <img src={about3} alt="Card image cap" className="w-90 mx-auto rounded my-2 w-auto cover height-vp-60 pic-center" />
            <ol className='text-left px-4 py-2 mx-2 text-secondary'>
                <li>We cook for a maximum of 200 guest, Family Members, Church associated community
                     and Grandpa Family
                </li>
                <li>We bring our own <span className='text-pink'>cook, dish-up & clean </span></li>
                <li>The family get a chance to attend the <span className='text-pink'>Funeral Services </span> get the Breakfast
                  </li>

                <li>We bring our  <span className='text-pink'>Crockey & Cutievy</span></li>
                <li>We provide with grocery </li>
                <li> Tents plus table setup with decor, catering with skilled chef's that take care of Pelling, Food prep cooking & serving stations </li>
            </ol>

        </div>


        <Footer />
    </>)

}
