
import React, { useEffect } from "react";
import Sponsors from "../../components/Sponsors";
import MissionSlider from "../MissionSlider";
import BlueContainer from "../BlueContainer";
import Slider from '../Sliders';
import Logo from '../../assets/img/colored-logo.png';
import Background1 from '../../assets/img/home1.jpg';
import Catering from '../../assets/img/home3.jpg';
import Stone from '../../assets/img/home2.jpg';
import CompanySlogan from '../CompanySlogan';
import UserReviews from '../UserReview';
import Contact from '../Contact';
import Footer from "../Footer";
import Form from '../Form'
import Clients from '../Partners';
import './index.scss'


export default function Home() {

  const slides = [{ id: 1, showslide: "", title: "GPS Funerals Home", logo: Logo,  btn: "Join Us Now", bg: Background1,controls:[{id:1,name:"active"}, {id:3,name:"deactive"},{id:2,name:"deactive"}] },
  { id: 2, showslide: "hide", title: "GPS Funerals Home", logo: Logo, bg: Stone, btn: "Join Us Now", controls:[{id:1,name:"deactive"}, {id:3,name:"active"},{id:2,name:"deactive"}] },
  { id: 3, showslide: "hide", title: "GPS Funerals Home", logo: Logo, bg: Catering, btn: "Join Us Now", controls:[{id:1,name:"deactive"}, {id:3,name:"deactive"},{id:2,name:"active"}] }
  ];
  var count = 0;

  const changeSlide = (index) => {
    count = count + index;

    let el = document.getElementsByClassName("sliders");

    for (let i = 0; i < el.length; i++) {
      el[i].style.display = "none";
    }
    if (count >= el.length) {
      count = 0;

      el[count].style.display = "block";
    }
    else if (count < 0) {
      count = el.length - 1;
      el[count].style.display = "block";
    }
    else {
      el[count].style.display = "block";
    }

  }

  

  useEffect(() => {

    const intervalslide = setInterval(() => { changeSlide(1) }, 10000);
    return () => clearInterval(intervalslide);

  }, []);




  return (
    <>
         
      <div className='main container-fluid m-o p-0' style={{
        'backgroundColor': 'white'
      }}>
        <div className="home-slider w-100 container-fluid m-0 p-0">
          {slides.map((slide) => <Slider key={slide.id} id={slide.id} controls={slide.controls} logo={slide.logo} bg={slide.bg} btn={slide.btn} desc={slide.desc} showslide={slide.showslide} title={slide.title} />)}
        </div>
       
        <Clients />
        <MissionSlider />
        <BlueContainer />
        <CompanySlogan />
        <UserReviews />
        <div className={'d-flex form-group align-items-start'}>
          <Contact />
        <Form />
        </div>

        <Footer />
      </div>


    </>

  );






}