import "./index.scss";

function Footer() {
  return (
    <>
      <div
        id="footer"
        className="container-fluid mx-0 bg-pink text-center my-3 py-3"
      >
        {/* <h3 className='header-text text-black'>More About Us</h3>
         < hr className='bg-dark border border-dark p-0 m-0 w-90 mx-auto' />
        </div>

    <div className='footer1 container-fluid py-2 mx-0 my-3'>
            <div className='footer-content mx-1'>
                <p className='first-part'>
                    <p className='text-light'>GPS Funeral Home pty Ltd,<br/>
                    Reg No: 2022/451251 /07<br/>
                    GPS Funeral Home is registered JR <br/>
                    under FSP  No: <span className=' bolder-text bold-value'>50965</span></p>
                </p>
                <div className='sec-part text-white'>
                    <p className='gen-text tcs-trans' >  Terms and Conditions<br/>@2022 GPS Funeral Home</p>
                </div>
            </div>
            
    <div className='text-white text-right text-small small'> <b> Powered By <a href='https://www.weball.co.za' className='text-pink'>Weball Technologies</a></b></div>
    */}
        <div className="container-fluid position-relative p-0 m-p">
          <div className="p-0 container-fluid main-wrapper d-flex justify-content-around m-0">
            <div className="left container-fluid m-0 p-0">
              <h5 className="text-white text-yellow">Social Links</h5>
              <hr className={"text-white bg-white p-0 w-90"} />
              <ul className="list-unstyled d-flex justify-content-center">
                <li className={"my-icons-container"}>
                  <a href="">
                    <i className="fab fa-facebook-f fa-fw my-icon text-pink"></i>
                  </a>
                </li>
                <li className={"my-icons-container"}>
                  <a href="">
                    <i className="fab fa-youtube fa-fw my-icon text-pink"></i>
                  </a>
                </li>
                <li className={"my-icons-container"}>
                  <a href="">
                    <i className="fab fa-tiktok fa-fw my-icon text-pink"></i>
                  </a>
                </li>
                <li className={"my-icons-container"}>
                  <a href="https://wa.me/+2607625627">
                    <i className="fab fa-whatsapp text-pink fa-fw my-icon"></i>
                  </a>
                </li>
                <li className={"my-icons-container"}>
                  <a href="tel:+27106342230">
                    <i className="fas fa-phone  text-pink fa-fw my-icon"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <h5 className="text-white text-yellow">Short Links</h5>
          <hr className={"text-white bg-white w-90 p-0"} />

          <ul className="short-links-list list-unstyled d-flex justify-content-center footer-navbar text-white">
            <li>
              <a href="#home" className="short-links no-wrap">
                Home
              </a>
            </li>
            <li>
              <a href="#OurWork" className="short-links no-wrap">
                About Us
              </a>
            </li>
            <li>
              <a href="/register" className="short-links no-wrap">
                Join Now
              </a>
            </li>
            <li>
              <a href="#Contact" className="short-links no-wrap">
                Contact Us
              </a>
            </li>
          </ul>

          <div className={"container-fluid mx-0 my-2 p-0 w100"}>
            <div className="container-fluid p-0 d-flex justify-content-center subscribe mt-4">
              <div>
                <i className="fas fa-envelope"></i>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Enter your email here..."
                  name="Subscribeemail"
                />
              </div>
              <div className={"button-submit subscribe"}>
                <a href="Subscribe">Subscribe</a>
              </div>
            </div>
          </div>

          <div className="right text-left text-white px-4">
            Reg No: 2022/451251 /07
            <br />
            GPS Funeral Home and Events is registered JR <br />
            under FSCA No:{" "}
            <span className=" bolder-text bold-value">
              {" "}
              Juristic Representative Havana life 52444{" "}
            </span>
          </div>

          <div>
            <b className={"text-primary"}>
              Copyright @ 2023 All rights reserved - GPS Funeral Home PTY LTD
            </b>
          </div>

          <div className="text-white text-right text-small small mb-4">
            {" "}
            <b>
              Website Developed By{" "}
              <a href="https://www.weball.co.za" className="text-blue">
                Weball Technologies
              </a>
            </b>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
