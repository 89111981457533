import React, { useEffect, useState } from 'react';
import './index.scss';
import Button from '../../../components/ButtonPink';
import image from '../../../assets/img/3d-logo-dark-bg.jpg';
import about1 from '../../../assets/img/funeral services 3.jpg';
import about2 from '../../../assets/img/funeral services 2.jpg';
import about3 from '../../../assets/img/funeral services 1.jpg';
import Footer from "../../Footer";






export default function FuneralServices() {

    const photos = [{ id: 1, caption: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore", img: image },
    { id: 2, caption: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore", img: image }]


    return (<>
        <div className='container-fluid mx-0 mb-4 p-1 bg-white text-center'>

            <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
                <div className='h3 animate__animated animate__fadeInDown'>
                    <Button name="Funeral Services" styles="h3" />
                </div>
            </div>

            <div className='container-fluid mx-0 my-2 p-0  manage-services go-flex'>


                <div className="card my-2 mx-2 rounded shadow img-70" >
                    <a href={about1}><img className="card-img-top" src={about1} alt="Card image cap" /></a>
                    <div className="card-body p-1 bg-secondary text-white text-left">
                        <ul>
                            <li>We provide all tools necessary to get the jobs done. Our team 
                                will assist with grave closure and placing of weather, Diphiri, grave closure
                                
                            </li>
                            <li>We offer conveniences, flexibility value for money and tailor made services to suit your budget.</li>
                        </ul>
                    </div>
                </div>

                <div className="card my-2 mx-2 rounded shadow img-70" >
                    <a href={about2}><img className="card-img-top" src={about3} alt="Card image cap" /></a>
                    <div className="card-body p-1 bg-secondary text-white text-left">
                        <ul>
                            <li>GPS Funeral home and Events brings a variety of cars to carry the family on the funeral day driven by professional, fully insured and 
                                uniformed chauffeurs 
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <hr className="my-2 mx-auto w-90 bg-secondary border" />
            <img src={about2} alt="Card image cap" className="w-90 mx-auto rounded my-2  img-70" />
            <ol className='text-left px-4 py-2 mx-2 text-secondary'>
                <li>Don't grave alone</li>
                <li>GPS provide <span className='text-pink'>Transport for human remain</span></li>
            </ol>
        </div>


        <Footer />
    </>)

}
