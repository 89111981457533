import Button from '../../components/ButtonPink';
import Footer from "../Footer";
import Img1 from '../../assets/img/cow policy 1.jpg'
import Img2 from '../../assets/img/cow policy 3.jpg'
import Img3 from '../../assets/img/cow policy 2.jpg'
import Img4 from '../../assets/img/policy4.jpg'
import Img5 from '../../assets/img/policy5.jpg'
import ImageGallery from "react-image-gallery";

const images = [
    {
        original:Img1,
        thumbnail:Img1
    },
    {
        original:Img2,
        thumbnail:Img2
    },
 {
        original:Img3,
        thumbnail:Img3
    },
  
]
function Cards(props){
    const count = props.menu.length/2;
const items = props.menu;
const firstHalf = items.slice(0, count);
const secondHalf = items.slice(count, count * 2);

    return (
        <>
      <div className="card  my-3 p-0 container-fluid card-30" >
                <div className="card-header bg-pink text-white text-center">
                  <b>{props.title}</b>
                </div>
                <div className=" card-body text-left ">
                    <div className={'text-start container-fluid'}> {items.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                </div>
        </div>
        </>
    )
}

function Card_2(props){
    const count = props.menu.length/2;
const items = props.menu;
const firstHalf = items.slice(0, count);
const secondHalf = items.slice(count, count * 2);

    return (
        <>
      <div className="card  my-3 p-0 container-fluid " >
                <div className="card-header bg-pink text-white text-center">
                  <b>{props.title}</b>
                </div>
                <div className=" card-body text-left go-flex">
                    <div className={'text-start container-fluid'}> {firstHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                      <div className={'text-start container-fluid'}> {secondHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                </div>
        </div>
        </>
    )
}

export default function CowPolicy() {

    const items = [
        {
            title: "Age 0-94+ Years",
            menu: [
                'Cow Plan Payout R10 000', 'Single R180', 'Couples R230 '    ]
        },
          {
            title: "Age 18-64 years",
            menu: [
                'Payout R15 000',            ]
        },
         {
            title: "Family R230",
            menu: [
                'Payout R15 000',            ]
        },


    ];


    return (<>
        <div className='container-fluid mx-0 mb-4 p-1 bg-white text-center'>
            <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
                <div className='h3 animate__animated animate__fadeInDown'>
                    <Button name="Cow Policy" styles="h4" />
                </div>
            </div>

            
    <div className='container-fluid mx-0 my-3 p-0 '>
          <div className="card my-2 mx-1 " >
                <div className="card-header bg-secondary text-white text-center">
                <b>For cow plan you can add anyone including children you pay R50</b>
                </div>

        </div>

         <div className=" text-left  d-pad-4 go-flex mx-2 ">
                    {items.map((item, index)=><Cards title={item.title} menu={item.menu} key={index}/>)}
                </div>

       </div>

             <hr className="mb-3 mt-0 mx-auto w-90 bg-secondary border-dark" />


            <div className='container-fluid mx-auto my-3 p-0 text-center go-flex'>
            <div className="card my-2 mx-1 rounded card-50" >


            <div className={'card-img-top height-vp-50 cover '}>
                <ImageGallery
                    items={images} autoPlay={true} showNav={false}
                    showThumbnails={false} fullscreen={true}
                    showFullscreenButton={false} showPlayButton={false}
                    showBullets={true} showIndex={true}
                    slideDuration={500} slideInterval={3000}
                />
            </div>

            <div className={'container-fluid card-body m-auto p-1 bg-secondary text-white text-center z-index-9'}>
<p>
            We also have an option for cash funerals... We provide you with a tailor made funeral package that suits your budget
There is no strategy that one can use for grieving, death comes unplanned, it is unpredictable. You need a family that's going to be with you all step of the way. Comfort in your Grieving is what we promise you... We also offer grief counselling and more 
</p>
<p>

The death of a loved one is heartbreaking, please don't add the stress of not knowing how you'll bury your loved one on top of the heart break. Let us take care of your burial plan...  
We exist because we care about why we bring Comfort in your Grieving.

</p>
            </div>

                 </div>
            </div>

       <div className='container-fluid mx-0 my-2 p-0 d-flex flex-wrap justify-content-around manage-services'>

            </div>

            <hr className="my-2 mx-auto w-90 bg-secondary border" />
            <div className='container-fluid px-2 py-2 text-white bg-secondary text-left rounded shadow'>
                <ul>
                    <li>Couples R150 2 Members Mrs/Mr</li>
                    <li>Single per person R70</li>
                    <li>Couples (60-75) years <br/> Single M R170 <br/> Ages <br/> Payout R15 000 </li>
                </ul>
            </div>



        </div>
        <Footer />
    </>)

}
