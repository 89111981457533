import Button from '../../components/ButtonPink';
import Footer from "../Footer";
import Img1 from '../../assets/img/clean1.webp'
import Img2 from '../../assets/img/clean2.jpg'
import Img3 from '../../assets/img/clean3.jpg'



const imageCards = [
    {
        image:Img1,
        body:'We take care of all your cleaning after funeral by the next day even washing the blankets bonus with stay soft and washing powder'
    },
      {
        image:Img2,
        body:'We\'d like you to focus on things that matter to you and we\'re cleaning after the funeral isn\'t one of them'
    },


]

const pars = [
   'Planning ahead secures and protects your funeral'
]
function Card_2(props){
    const count = props.menu.length/2;
const items = props.menu;
const firstHalf = items.slice(0, count);
const secondHalf = items.slice(count, count * 2);

    return (
        <>
      <div className="card  my-3 p-0 container-fluid " >
                <div className="card-header bg-pink text-white text-center">
                  <b>{props.title}</b>
                </div>
                <div className=" card-body text-left go-flex">
                    <div className={'text-start container-fluid'}> {firstHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                      <div className={'text-start container-fluid'}> {secondHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                </div>
        </div>
        </>
    )
}

function ImageCard(props){
   const item = props.item;

  return ( <div className={'card card-45 rounded my-3 justify-content-around mx-auto'}>
      <img src={item.image} className={'card-img-top height-vp-60 cover'}/>
      <div className={'card-body bg-secondary text-white'}>
          <p className={'card-text'}>
              {item.body}
          </p>
      </div>

   </div>)

}
export default function AfterCarePolicy() {

    const items = [

        {
            title: "Less than 1 year old",
            menu: [
                'Hearse','Body Coffin','Body Preparer','Grave Side','Flowers'
            ]
        },
          {
            title: "1 to 4 years old",
            menu: [
                'Hearse','Family Car','Body Coffin','Grave Side','Flowers'
            ]
        },
         {
            title: "5 years and older",
            menu: [
                'Services','Hearse','Family Car','Coffin','Grave side','Flowers'
            ]
        },


    ];
    const items2 = [

        {title:"After care burial",
        menu:["Single R40","Couples R57","Family plan R80",
        "6 members R100","8 members R130","10 members R150","14 members R200"]}

        ];

    return (<>
        <div className='container-fluid mx-0 mb-4 p-2 bg-white text-center'>
            <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
                <div className='h3 animate__animated animate__fadeInDown'>
                    <Button name="After Care Policy" styles="h4" />
                </div>
            </div>



     <div className={'container-fluid'}>

            {items2.map((item, index)=><Card_2 title={item.title} menu={item.menu} key={index}/>)}
    </div>

              <div className={'container-fluid m-0 p-0 d-flex flex-wrap flex-row'}>
                {
                    imageCards.map((imageCard, index)=><ImageCard item={imageCard}/>)
                }
            </div>




       <div className='container-fluid mx-0 my-2 p-0 d-flex flex-wrap justify-content-around manage-services'>

            </div>







            <div className={'container-fluid'}>
                <div className={'card rounded'}>
                    <img className={'card-img height-vp-60 cover'} src={Img3}/>
                    <div className={'container-fluid bg-op card-img-overlay text-white text-center d-flex flex-column justify-content-center p-3'}>
                        {
                            pars.map((par, index)=> <p><h2 key={index}>
                                {par}
                            </h2></p>)
                        }
                    </div>
                </div>

            </div>

            <div className='px-2 my-3 text-left'>
                <p>We take of all your cleaning after funeral by the next day even washing the blanket bones with stay soft with washing powder 
Planning ahead secures and protects your funeral funds.
If you decide to prefund as well as preplan your funeral arrangements, you can ensure that the funds to pay for the funeral are secure and protected
</p>
<p>
<b>Planning ahead saves you money.</b> <br/>
Often, the family wants "only the best" for their loved one  or their financial decisions are landed by grief. This is why it is a good idea to plan ahead. To make financial decisions with a clear vital mind rather than one affected by emotional strains. In this way, you can control the ending budget for the cost of your funeral. 

</p>
<p>
Planning ahead ensures that your wishes are known. GPS Funeral Home and Events will work with you to determine which options best fit your wishes, your budget and your family's need.This week I am going to give you 5 reasons of why it is important for you to have a funeral plan.
</p>
            </div>




        </div>
        <Footer />
    </>)

}
