import "./index.scss";

function ReviewComponet(props) {
  return (
    <div className="card review-container bg-white border animate__animated animate__fadeInUp my-4">
      <div className="card-body">
        <div className="d-flex align-items-center">
          <div className="w-100 container-fluid">
            <div className="w-100 text-center">
              <img
                src={props.imageUrl}
                alt={"Picture"}
                className="rounded-circle mr-3"
                style={{ width: "150px", height: "150px", objectFit: "cover" }}
              />
            </div>
            <h5 className="mb-1 text-center my-3">{props.name}</h5>

            <p className="mb-0">{props.text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewComponet;
