import Button from '../../components/ButtonPink';
import Footer from "../Footer";
import Img1 from '../../assets/img/tomb1.jpg'
import Img2 from '../../assets/img/tomb2.jpg'
import Img3 from '../../assets/img/tomb3.jpg'
import Img4 from '../../assets/img/tomb4.jpg'
import Img5 from '../../assets/img/tomb5.jpg'
import prices from '../../assets/img/PriceList.png'



const imageCards = [
    {
        image:Img1,

    },
      {
        image:Img2,
    },
     {
        image:Img3,
    },
     {
        image:Img4,
    },
     {
        image:Img5,
    },


]

const pars = [
   'For the tombstone you pay monthly and get it on the day of them funeral or you can lay-bye',
    'Offers tombstone price and services that are affordable without compromising on quality. We ensure you leave an everlasting memories of your loved one and generation to come',
    'Where you and your beloved one will be buried you might wish to build a tombstone and know how difficult to get one, don\'t worry we are here for you'
]
function Card_2(props){
    const count = props.menu.length/2;
const items = props.menu;


    return (
        <>
      <div className="card  mx-0 my-3 p-0 container-fluid " >
                <div className="card-header bg-secondary text-white text-center">
                  <b>{props.title}</b>
                </div>
                <div className=" card-body text-left go-flex">
                    <div className={'text-start container-fluid'}> {items.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>

                </div>
        </div>
        </>
    )
}

function ImageCard(props){
   const item = props.item;

  return ( <div className={'card card-30 rounded my-3 justify-content-around mx-auto'}>
      <img src={item.image} className={'card-img-top height-vp-60 cover'}/>


   </div>)

}
export default function TombstonePolicy() {

    const items = [

        {
            title: "Less than 1 year old",
            menu: [
                'Hearse','Body Coffin','Body Preparer','Grave Side','Flowers'
            ]
        },
          {
            title: "1 to 4 years old",
            menu: [
                'Hearse','Family Car','Body Coffin','Grave Side','Flowers'
            ]
        },
         {
            title: "5 years and older",
            menu: [
                'Services','Hearse','Family Car','Coffin','Grave side','Flowers'
            ]
        },


    ];
    const items2 = [
        {
            title:'How it works',
            menu:pars,
        }


        ];

    return (<>
        <div className='container-fluid mx-0 mb-4 p-2 bg-white text-center'>
            <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
                <div className='h3 animate__animated animate__fadeInDown'>
                    <Button name="Tombstone Policy" styles="h4" />
                </div>
            </div>

            <div className={'container-fluid row p-0 m-0'}>
                <img src={prices} className = "col-sm-6 mx-auto my-2 p-0"  alt = "prices" />

            </div>



     <div className={'container-fluid p-0 m-0'}>

            {items2.map((item, index)=><Card_2 title={item.title} menu={item.menu} key={index}/>)}
    </div>

              <div className={'container-fluid m-0 p-0 d-flex flex-wrap flex-row'}>
                {
                    imageCards.map((imageCard, index)=><ImageCard item={imageCard}/>)
                }
            </div>




       <div className='container-fluid mx-0 my-2 p-0 d-flex flex-wrap justify-content-around manage-services'>

            </div>







            {/*  <div className={'card-img-top img-fluid container-fluid cover m-0 p-0 '}>*/}
            {/*    <ImageGallery className={'card-50 height-vp-60'}*/}
            {/*        items={images} autoPlay={true} showNav={false}*/}
            {/*        showThumbnails={false} fullscreen={true}*/}
            {/*        showFullscreenButton={false} showPlayButton={false}*/}
            {/*        showBullets={true} showIndex={true}*/}
            {/*        slideDuration={500} slideInterval={3000}*/}
            {/*    />*/}
            {/*</div>*/}
            {/* <div className={'container-fluid mt-4 pt-4 text-gray'}>
                <p>
                    risk cover
                </p>
            </div> */}


        </div>
        <Footer />
    </>)

}
