import React,{useEffect,useState} from 'react';
import './index.scss';
import Button from '../../components/ButtonPink';
import ButtonSquare from '../../components/ButtonSquare';
import { APIurl,rootUrl,BaseAPIurl } from '../../App';
import axios from 'axios';
import MSGDialogue from '../../components/Dialogue';
import ImageGallery from 'react-image-gallery';


function Card_2(props){
  const count = props.menu.length/2;
const items = props.menu;
const firstHalf = items.slice(0, count);
const secondHalf = items.slice(count, count * 2);

  return (
      <>
    <div className="card  my-3 p-0 container-fluid " >
              <div className="card-header bg-pink text-white text-center">
                <b>{props.title}</b>
              </div>
              <div className=" card-body text-left go-flex">
                  <div className={'text-start container-fluid'}> {firstHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
                    <div className={'text-start container-fluid'}> {secondHalf.map((item,index)=><span key={index} ><i className='fa fa-circle-check text-pink '></i> {item} <br/></span>)}</div>
              </div>
      </div>
      </>
  )
}

const items2 = [

  {
    title:'Galilee Plan',
    menu:[
    "10kg samp",
    "10kg rice",
    "10kg Mabele",
    "10kg SUGER",
    "2x3kg mayonnaise",
    "12,5 maize meals",
    "3kg chutney",
    "50s plastic spoons",
    "DISPOSAL plate",
    "2x7 kg potatoes",
    "Box of tomatoes",
    "7kg butternut bags",
    "10s Cabbage bags",
    "800g various spices",
    "1kg salt fine and coarse",
    "Cooking oil 2x5L",
    "5L juice concentrated",
    "Coffee of cream",
    "500g Rooibos"
    ]

  },

  {
    title:'Micah Plan',
    menu:[
     " 5kg tomatoes",
     " 2x5L cooking oil",
     " Rice 10kg",
      "Bags Cabbage 7s",
     " Bags of onions",
     " 10kg BEETROOTS",
     " Packs of soups",
      "Salt 1kg",
      "SUNLIGHT wash dishes",
      "Pot Scrub pack",
      "Steel wool pack"
    ]
  },
  
  {title:"Casket services", menu:["Coffin casket",
  "Hearse",
  "2 family cars",
  "Storage","Tents and chairs",
  "Catering team and groceries",
  "Equipment","Removed, collected, mortuary services",
  "Programs ", " Grave name","Fresh flowers ",
  "Funeral arrangements ",
  "Grave setup",
  "Church trolley ",
  "Screen",
  "Tissue",
  "Bottle of water",
  " Bottles of juice",
  "Lowering devices",
  "Documents processing"]}
  
  ];


export default function Grocery(props){

    const [photos,setPhotos] = useState([]);
    const [limit,setLimit] = useState(4);
    const [isEmpty,setEmpty] = useState("Loading Images");
    const [loginmsg, setLogingmsg] = useState("");
    const [colors, setColors] = useState("");
    const [logging, setLogging] = useState(false);
    const [diaglogueID, setdiaglogueID] = useState("msg16");

    useEffect(()=>{

    // //Get all photos
    // setLogingmsg("Getting Photos " + props.name +  " ...... ");
    // setColors(" text-white bg-secondary");
    // try {
    //   document.getElementById(diaglogueID).style.display = "block";
    // } catch (error) {
    //   console.log("element is not found");
    // }
    // setPhotos([]);
    // axios.post(APIurl,{
    //     cmd: "getphotobyid",
    //     id:props.name

    //     } )
    //     .then((response) => {

    //     var res = response.data;



    //     if(!res["success"]){
    //       console.log("check this ",res);
    //       setLogingmsg(res["data"]);
    //       setColors("Could not send request");
    //       setEmpty("No photo found");
    //     }
    //     else{

    //         if(res["data"].length ==0){

    //             setEmpty("No photo found");
    //           }
    //           else{
    //             setEmpty("");
    //           }


    //       const images = [];

    //       res["data"].forEach(element => {

    //         let el = { original: BaseAPIurl + "/Gallery/" + element.img,
    //                    thumbnail: BaseAPIurl + "/Gallery/" + element.img};
    //         images.push(el);

    //       });
    //       setPhotos(images);
    //       document.getElementById(diaglogueID).style.display = "none";
    //     }

    //     })
    //     .catch((error) => {

    //     console.log(error);

    //     });



    },[props]);

    const grocery = [

        {
            title: "Decorations",
            menu: ['Dripping with setup', 'Pyramid tent / Freme tents', 'Padded gold rim chairs', 'Turfs, green mats Roles', 'White gloss Floring tor tents','Red carpets', 'Stage decor','couches'

            ]
        },
          {
            title: "Additional Extras",
            menu:['Live Stream','TV', 'Screens','Grave Closure','Sound at home','Sound at grave side','Mobile toilets','Portable','Hand wash','Basic pyramid tents', 'Freme Tent', 'Marquees']
        },

    ];


    return(<>

{logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}

    <div className='container-fluid m-0 p-1 bg-white'>

        <div className='container-fluid my-3 mx-0 d-flex justify-content-around'>
            <div className='h3'>
                <Button name= {props.name + " Gallery"} styles = "h3"/>
            </div>
        </div>
        <div className='container-fluid mx-0 p-x'>
          <div className='bg-secondary text-white mx-1 p-2 rounded'>
            <p>
            Storage of the body and preparation of the body casket (coffin) and spray. We provide all 
            tools necessary to get the job done, and our team will assist with grave closure and the
             placing of wreaths. We do all the setup 
            for the decor at the graveside. We strive to be the leader in the funeral services industry

            </p>
            
          </div>

        </div>
        
        <div className={'container-fluid'}>

{items2.map((item, index)=><Card_2 title={item.title} menu={item.menu} key={index}/>)}
</div>


        <div className='container-fluid mx-0 my-3 p-0 text-center'>
            <h4>Photos</h4>
            <h3 className='text-danger'>{isEmpty}</h3>
        </div>



        {/* <ImageGallery autoPlay= {true} items={photos} /> */}

        <ButtonSquare name="Join Our Funeral Services" Icon="plus" />
    </div>

    </>)

}
